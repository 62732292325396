import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
import { notify } from '../../../common/functions/react-toastify.function';
export const assignPermissionsToTenants = async (id, updatedTenants) => {
  try {
    const response = await apiClient.post(
      `/tenant/assignTenantPermissions/${id}`, 
      updatedTenants, 
    );
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error assigning feature to permissions:', error);
    // throw error;
  }
};
