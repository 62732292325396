import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
import { notify } from '../../../common/functions/react-toastify.function';
export const addRouteForPoc = async (geoJson) => {
  try {
    const response = await apiClient.post(
      `/routes/createRoute/`, 
      geoJson, 
    );
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};
