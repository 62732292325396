import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { borderRadius } from '@mui/system';
import { TextField, Grid, Switch, FormControlLabel, FormGroup ,FormControl, MenuItem, InputLabel, Select, FormHelperText} from '@mui/material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import apiClient from '../../common/functions/api_axios_instance.function';
import {    } from '@mui/material';
import { PocTypes } from '../../enums/soilTypes.enum';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 413,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    //   boxShadow: 24,
    p: 4,
};

export default function PocEstimationModalCost(props) {
    const { open, handleClose,setRouteName,handleLockRoute,routeName } = props;
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const [pocObj, setPocObj] = useState(routeName);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPocObj(value);
        setRouteName(value)
    };

    const handleCloseModal = () => {
        setPocObj('')
        handleClose();
    };


    const handleSubmitPoc = () => {
        showBackdrop()
        handleLockRoute()
        handleClose()
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" style={{
                        // fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal'
                    }}>
                        Fiber Route Name
                    </Typography>
                    <Grid container spacing={2} paddingTop={'20px'}>
                 
                        
                        <Grid item xs={12}>
                            <TextField id="standard-basic" required value={routeName} onChange={handleChange} name='name' label="route name" variant="outlined" sx={{ width: '350px' }} />
                        </Grid>
                       
                       
                        <Grid container spacing={2} style={{ marginLeft: '11px', marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    backgroundColor: '#BD1D23', width: '160px',
                                    '&:hover': {
                                        backgroundColor: '#BD1D23',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#E57373',
                                        color: '#fff',
                                    },
                                }}
                                    onClick={handleSubmitPoc}
                                >Submit</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    color: '#000', backgroundColor: 'white', width: '160px', '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                    },
                                }} onClick={() => handleCloseModal()}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}