import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
import { notify } from '../../../common/functions/react-toastify.function';
export const getRoutesForPoc = async () => {
  try {
    const response = await apiClient.get(
      `/routes/getAllRoutes/`, 
    );
    console.log(response)
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};

export const getAdditionalCostsForPoc = async () => {
  try {
    const response = await apiClient.get(
      `/routes/getAllAdditionalCost/`, 
    );
    console.log(response)
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};

export const getRouteDetailTable = async (startLocation, endLocation) => {
  try {
    const response = await apiClient.get(
      `/routes/getRouteDetailTable/${startLocation}/${endLocation}`, 
    );
    console.log(response)
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};

export const getRouteCostsForPoc = async (id) => {
  try {
    const response = await apiClient.get(
      `/routes/getRouteCost/${id}`, 
    );
    console.log(response)
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};

export const getRouteOneForPoc = async (id) => {
  try {
    const response = await apiClient.get(
      `routes/getOneRoute/${id}`, 
    );
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};
export const getRouteOneAdditionlForPoc = async (id) => {
  try {
    const response = await apiClient.get(
      `/routes/getOneRouteAdditional/${id}`, 
    );
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};

export const getRouteOneAdditionlCount = async (id) => {
  try {
    const response = await apiClient.get(`/routes/countAdditional/${id}`,);
    return response.data;
  } catch (error) {
    if(error.response.status === 401) {logout()}
    notify('error', error.response.message)
    console.error('Error:', error);
    // throw error;
  }
};