import PocRouteAnalysisHeaderComponent from './poc_route_analysis_header.componenet'
import { Box } from '@mui/material';
import RouteAnalysisDetailTable from './poc_comparison_detail'
import React, { useState, useEffect } from 'react';
import { getRoutesForPoc} from '../functions/get_routes_for_poc.function';


export default function RouteAnalysisTable () {
    const [routes,setRoutes] = useState([])

    useEffect(() => {
        const fetchRoutes = async () => {
          try {
            let routesData = await getRoutesForPoc();
            setRoutes(routesData); // Save the fetched routes
          } catch (error) {
            console.error('Error fetching routes:', error);
          }
        };
    
        fetchRoutes();
      }, []);
    return(
        <>
        <PocRouteAnalysisHeaderComponent routes={routes}/>
        <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',  // Center horizontally
            alignItems: 'center',      // Center vertically
            height: 'calc(100vh - 50px)', // Adjust based on the header height
            backgroundColor: '#f4f4f4', // Optional background color
            padding: 2
        }}
            >
        <RouteAnalysisDetailTable/>
        </Box>

        </>
    )
}