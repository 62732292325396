// import React, { useState } from 'react';
// import { Box, Grid, Typography, Button } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import './style.css';
// import AddNewTenantModal from './add_new_tenant_modal.component';
// import LogoutIcon from '@mui/icons-material/Logout';
// export const TenantHeader = ({ handleLogout }) => {
//   const [openNewTenant, setOpenNewTenant] = useState(false)

//   const handleAddNewTenantClose = () => {
//     setOpenNewTenant(false)
//   }
//   const handleOpen = () => {
//     setOpenNewTenant(true)
//   }

//   return (
//     <>
//       <AddNewTenantModal open={openNewTenant} handleClose={handleAddNewTenantClose} />
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mt={2}
//         mb={2}
//         width={'99%'}
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: 'bold',
//             textTransform: 'uppercase',
//             letterSpacing: '2px',
//             background: 'linear-gradient(90deg, #BD1D23, #FF5733)',
//             WebkitBackgroundClip: 'text',
//             color: 'transparent',
//             animation: 'gradientMove 3s ease-in-out infinite',
//           }}
//         >
//           Tenant Management
//         </Typography>
//         <Button
//           variant="contained"
//           sx={{
//             '&:hover': {
//               borderColor: '#BD1D23',
//               backgroundColor: 'rgba(189, 29, 35, 0.1)',
//             },
//           }}
//           style={{ backgroundColor: '#BD1D23' }}
//           startIcon={<AddIcon />}
//           onClick={handleOpen}

//         >
//           New Tenant
//         </Button>
//         <LogoutIcon sx={{ color: '#BD1D23', cursor: 'pointer' }} onClick={handleLogout} />
//       </Box>
//     </>
//   );
// };

import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import AddNewTenantModal from './add_new_tenant_modal.component';
import './style.css';

export const TenantHeader = ({ handleLogout }) => {
  const [openNewTenant, setOpenNewTenant] = useState(false);

  const handleAddNewTenantClose = () => {
    setOpenNewTenant(false);
  };

  const handleOpen = () => {
    setOpenNewTenant(true);
  };

  return (
    <>
      <AddNewTenantModal open={openNewTenant} handleClose={handleAddNewTenantClose} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={2}
        width={'99%'}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            background: 'linear-gradient(90deg, #BD1D23, #FF5733)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            animation: 'gradientMove 3s ease-in-out infinite',
          }}
        >
          Tenant Management
        </Typography>

        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            sx={{
              '&:hover': {
                borderColor: '#BD1D23',
                backgroundColor: 'rgba(189, 29, 35, 0.1)',
              },
            }}
            style={{ backgroundColor: '#BD1D23' }}
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            New Tenant
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#6C757D',
              '&:hover': {
                backgroundColor: '#5A6268',
              },
            }}
            endIcon={<LogoutIcon />}
            onClick={handleLogout}
          >
            LogOut
          </Button>
        </Box>
      </Box>
    </>
  );
};