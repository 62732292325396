import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, Switch, Grid, Divider, Checkbox, Chip } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { getTenantDetail } from './tenantFunctions.js/getTenantDetails';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import { assignPermissionsToTenants } from './tenantFunctions.js/assign_permissions_to_tenant';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '4px',
    p: 2,
    width: '500px',
    maxHeight: '67vh',
    overflowY: 'auto',
};

export default function AssignPermissionsToTenantModal(props) {
    const { open, handleClose, rowData, fetchTenants } = props;
    const [oneTenantDetails, setOneTenantDetails] = useState([]);
    const [permissions, setPermissions] = useState({});
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const selectedTenants = oneTenantDetails?.filter(per => permissions[per.name]); // Only show selected tenants

    useEffect(() => {
        if (rowData?.id) {
            fetchOneTenantPermissions(rowData.id);
        }
    }, [rowData]);

    const fetchOneTenantPermissions = async (permissionId) => {
        showBackdrop();
        const allTenantsData = await getTenantDetail(permissionId);
        hideBackdrop();
        setOneTenantDetails(allTenantsData?.response);
       };

    useEffect(() => {
        if (oneTenantDetails?.length > 0) {
            const initialAssignedGroups = {};
            oneTenantDetails.forEach(group => {
                initialAssignedGroups[group.name] = group.active;
            });
            setPermissions(initialAssignedGroups);
        }
    }, [oneTenantDetails]);

    const handleToggle = (name) => (event) => {
        setPermissions({ ...permissions, [name]: event.target.checked });
    };

    const handleDelete = (name) => () => {
        setPermissions({ ...permissions, [name]: false });
    };

    const handleUpdate = async () => {
        showBackdrop();
        const activeTenantIds = oneTenantDetails
            .filter(tenant => permissions[tenant.name]) // Check if the permission is active
            .map(tenant => tenant.id); // Extract the ID of active tenants

        if (activeTenantIds.length === 0) {
            notify('warning', 'No tenants selected');
            hideBackdrop();
            return;
        }

        try {
            console.log('tenants id',activeTenantIds)
            const resp =await assignPermissionsToTenants(rowData?.id, activeTenantIds);
            resp?.response && notify('success', 'Permissions Assigned Successfully');
            await fetchTenants();
            hideBackdrop();
            handleClose();
        } catch (err) {
            notify('error', err?.response?.data?.message || err.message || 'Something went wrong');
            console.error('Error updating permissions:', err);
            hideBackdrop();
        }
    };

    const colorStyles = [
        {
            backgroundColor: '#FFF7E0', // Light yellow
            color: '#D89200', // Dark yellow
            borderColor: '#D89200', // Dark yellow border
        },
        {
            backgroundColor: '#F5F3FF', // Light purple
            color: '#7C3AED', // Dark purple
            borderColor: '#C4B5FD', // Dark purple border
        },
        {
            backgroundColor: '#FEF2F2', // Light red
            color: '#DC2626', // Dark red
            borderColor: '#FCA5A5', // Dark red border
        },
        {
            backgroundColor: '#E0FFF7', // Light green
            color: '#00A692', // Dark green
            borderColor: '#00A692', // Dark green border
        },
        {
            backgroundColor: '#F0F4FF', // Light blue
            color: '#0066CC', // Dark blue
            borderColor: '#0066CC', // Dark blue border
        },
        {
            backgroundColor: '#FFF0F6', // Light pink
            color: '#FF4081', // Dark pink
            borderColor: '#FF4081', // Dark pink border
        },
        // Add more styles if needed
    ];

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-title"
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '24px',
                            marginBottom: '16px',
                            background: 'linear-gradient(90deg, #BD1D23, #FF5733)',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            textAlign: 'center',
                        }}
                        gutterBottom
                    >
                        Assign Permissions To {rowData?.name}
                    </Typography>

                    <Divider />

                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        {oneTenantDetails?.map((ten) => (
                            <Grid container key={ten.id} alignItems="center" spacing={1}>
                                <Grid item xs={2}>
                                    <Checkbox
                                        color="error"
                                        checked={permissions[ten.name] || false}
                                        onChange={handleToggle(ten.name)}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body1" sx={{ color: '#1D242E' }}>
                                        {ten.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container spacing={1} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            {selectedTenants?.map((group, index) => {
                                const styles = colorStyles[index % colorStyles.length];
                                return (
                                    <Chip
                                        key={group.id}
                                        label={group.name}
                                        sx={{
                                            marginRight: 1,
                                            marginBottom: 1,
                                            backgroundColor: styles.backgroundColor,
                                            color: styles.color,
                                            borderColor: styles.borderColor,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderRadius: '6px',
                                            fontWeight: 'bold',
                                        }}
                                        onDelete={handleToggle(group.name)}
                                    />
                                );
                            })}
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2 }} />

                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    backgroundColor: '#BD1D23',
                                    '&:hover': {
                                        backgroundColor: '#9c1a1e',
                                    },
                                    color: '#fff',
                                }}
                                onClick={handleUpdate}
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    borderColor: '#BD1D23',
                                    '&:hover': {
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                    },
                                    color: '#BD1D23',
                                }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
