export const addMinutesToDateTime =(dateTimeString, minutesToAdd)=> {
    const date = new Date(dateTimeString);
    
    // Add the specified minutes
    date.setMinutes(date.getMinutes() + minutesToAdd);
    
    // Format the updated date and time as "YYYY-MM-DD HH:mm:ss"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  const updatedDateTime = addMinutesToDateTime('2024-10-20 16:30:33', 15);
  console.log(updatedDateTime);
  