import React, { useState, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { Box, Typography, TextField, Button, Popper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getRoutesForPoc} from '../functions/get_routes_for_poc.function';
import SideNavPOC from  '../sideNavPOC/SideNavPOC'
const PocRouteAnalysisHeaderComponent = ({routes}) => {
    const navigate = useNavigate();  
    const [selectedNav,setSelectedNav] = useState('')
    
    
    return (
        <>
          <SideNavPOC  routes={routes} setSelectedNav={setSelectedNav}/>

            <Box
                sx={{
                    backgroundColor: '#192126',
                    border: '1px solid #333333',
                    color: '#fff',
                    height: '50px',
                    fontFamily: 'Inter, sans-serif',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        py: '12px',
                        mx: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained" sx={{
                            backgroundColor: '#3A474F',
                            fontSize: '11px',
                            '&:hover': {
                                border: '1px solid #bd1d23',
                                fontSize: '11px',
                                // backgroundColor: '#bd1d23',
                                color: 'white',
                                boxShadow: 'inset 0 0 10px #bd1d23'
                            }
                        }}
                            className='table-icon'
                            onClick={() =>{navigate({pathname:'/poc', replace:true })}
                            }
                        >Create Routes</Button>     
                        </Box>                  
                </Box>
            </Box>
        </>
    );
};

export default PocRouteAnalysisHeaderComponent;