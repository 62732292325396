import React, { useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { addRouteForPoc } from './functions/add_route_for_poc.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import { notify } from '../../common/functions/react-toastify.function';
import { decodeGeometry } from '../../common/functions/decode_geometry_to_geojson.function';
import MapWithDirections from './poc_map_section.component';
import PocHeaderComponent from './poc_header.component';
import { getRoutesForPoc } from './functions/get_routes_for_poc.function';

const PocMainComponent = () => {
  // { start_location, end_location, geoJSON, length }
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routeName, setRouteName] = useState('')
  const [selectedRouteAdditional, setSelectedRouteAdditional] = useState(null);
  const [totalRouteCost,setTotalRouteCost]= useState(null)
  const [geoJson, setgeoJson] = useState([])
  const [routeSelection, setRouteSelection] = useState(false)
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [mapMarkers,setMapMarkers] = useState([])
  const [routes, setRoutes] = useState([]);
  const [removeMarkersSubRoute, setRemoveMarkersSubRoute] = useState(false);
  const [submitAdditional,setSubmitAdditional] = useState(false)

  const handleLockRoute = async () => {
    const chk =selectedRoute
    setSelectedRoute(selectedRoute)
    let geoData  =  selectedRoute.geoJSON?selectedRoute.geoJSON.coordinates:decodeGeometry(selectedRoute?.geometry)
    let startLoc = selectedRoute.start ? selectedRoute.start:geoData[0]
    let endLoc = selectedRoute.end ? selectedRoute.end:geoData[geoData.length-1]
    if (selectedRoute) {
      let pocAddRouteObjWithGeoJson = {
        start_location:startLoc,
        end_location:endLoc,
        length:selectedRoute.distance,
        geoJSON: {
          type: "LineString",
          coordinates:geoData
        },
        routeName:routeName
      }
      try {
        showBackdrop()
        const resp = await addRouteForPoc(pocAddRouteObjWithGeoJson)
        resp?.response && notify('success', 'Permissions Assigned Successfully');
        hideBackdrop();
        fetchRoutes()
      } catch (err) {
        notify('error', err?.response?.data?.message || err.message || 'Something went wrong');
        hideBackdrop();
      }
    } else {
      notify('error', 'Please Lock Route first');
      console.log('No route selected');
    }
  };

  useEffect(() => {
    fetchRoutes();
  }, [submitAdditional]);
  
  const fetchRoutes = async () => {
    try {
      let routesData = await getRoutesForPoc();
      setRoutes(routesData); // Save the fetched routes
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };
  return (
    <>
      <PocHeaderComponent handleLockRoute={handleLockRoute} selectRoute={selectedRoute} totalRouteCost={totalRouteCost}
       setRouteName={setRouteName} routeName={routeName} 
       selectedRouteAdditional={selectedRouteAdditional}
       routeSelection={routeSelection} setRouteSelection={setRouteSelection}
       mapMarkers={mapMarkers} setMapMarkers={setMapMarkers}
       setRemoveMarkersSubRoute = {setRemoveMarkersSubRoute}
       submitAdditional={submitAdditional} setSubmitAdditional={setSubmitAdditional}
       />
      <MapWithDirections setSelectedRoute={setSelectedRoute} selectedRoute={selectedRoute} 
      selectedRouteAdditional = {selectedRouteAdditional} setSelectedRouteAdditional = {setSelectedRouteAdditional}
       setTotalRouteCost={setTotalRouteCost} routeSelection={routeSelection} setRouteSelection={setRouteSelection}
       mapMarkers={mapMarkers} setMapMarkers={setMapMarkers} routes={routes}
       removeMarkersSubRoute={removeMarkersSubRoute} setRemoveMarkersSubRoute={setRemoveMarkersSubRoute}
       submitAdditional={submitAdditional} setSubmitAdditional={setSubmitAdditional}
    />
    </>
  );
};

export default PocMainComponent;