import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Skeleton } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { replaceUnderscoreWithTo } from '../../common/functions/replace_underscore_with_To.function';
import { convertDateToLocalString } from '../../common/functions/convert_date_to_local_string.function';

const regionColorsText = {
  'North': '#415F8C',
  'Central A': '#825144',
  'Central B': '#6E7A2F',
  'South': '#937535',
}

const regionColorsbackground = {
  'North': '#E8F1FD',
  'Central A': '#F9E5DF',
  'Central B': '#E9F3B6',
  'South': '#FDEECE',
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid #E8E9E9',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  borderRadius: "8px",
  boxShadow: "0 0 1px 0 #1a1f4360",
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    // width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  height: "44px",
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function ActiveAlarmsCard({ data, loading, value, alarmType, handleReset, handleApplyChanges, setApplyFilters, setTotalAlarms, totalAlarms, setSelectedAlarm, setFiltersValue, filtersValue, setRing, setCity, setRegion }) {
  const cardBgColor = '#F3FAFF';
  const cardBorderColor = '#C8E8FF';
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const stb =sessionStorage.getItem('selectedTab')
  const [dataArr, setDataArr] = useState([
    { name: 'North', count: 0, background: '#AFCEF9', color: '#000' },
    { name: 'Central A', count: 0, background: '#E39B88', color: '#000' },
    { name: 'Central B', count: 0, background: '#CBDD6A', color: '#000' },
    { name: 'South', count: 0, background: '#FFD886', color: '#000' },
  ]);

  useEffect(() => {
    // if(data?.length === 0){
    //   setDataArr([
    //     { name: 'North', count: 0, background: '#AFCEF9', color: '#000' },
    //     { name: 'Central A', count: 0, background: '#E39B88', color: '#000' },
    //     { name: 'Central B', count: 0, background: '#CBDD6A', color: '#000' },
    //     { name: 'South', count: 0, background: '#FFD886', color: '#000' },
    //   ])
    // }
    if (data) {
      setFilteredData(data);
      // CountForRegions(data);
    }
  }, [data, alarmType]);
  useEffect(()=>{
    console.log('hello',stb)
  },[stb])

  useEffect(() => {
    // if(filtersValue.region){
    // if (filteredData) {
    // setTimeout(() => {
    if (!filtersValue.region && filteredData) {
      CountForRegions(filteredData);
    }
    // }, 900);

    // }}
  }, [alarmType, filteredData]);
  // useEffect(() => {
  //   if (filteredData) {
  //     CountForRegions();
  //     // Calculate total alarms
  //     // const total = updatedDataArr.reduce((acc, cur) => acc + cur.count, 0);
  //     // setTotalAlarms(total);
  //   }
  // }, [sessionStorage.getItem('selectedTab')],data);
  const CountForRegions = () => {
    if (data?.length === 0 || alarmType === 'History') {
      setDataArr([
        { name: 'North', count: 0, background: '#AFCEF9', color: '#000' },
        { name: 'Central A', count: 0, background: '#E39B88', color: '#000' },
        { name: 'Central B', count: 0, background: '#CBDD6A', color: '#000' },
        { name: 'South', count: 0, background: '#FFD886', color: '#000' },
      ])
    }
    else {
      const updatedDataArr = dataArr && dataArr?.map(region => {
        const count = sessionStorage.getItem('selectedTab') === 'ipran' ? filteredData?.filter(alarm => alarm.source_region === region.name)?.length : filteredData?.filter(alarm => alarm.region === region.name)?.length;
        return { ...region, count };
      });
      setDataArr(updatedDataArr);
    }
  }
  useEffect(() => {
    if (data && searchQuery) {

      const filtered = data?.filter(alarm =>
        alarm?.layer_name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        alarm?.region?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        alarm?.city?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        alarm?.ring_name?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
      console.log('cc filtering', filtered)
      setFilteredData(filtered); // Set filtered data to filteredData state
    } else {
      // If there's no search query, reset filtered data to the original data
      setFilteredData(data);
    }
  }, [data, searchQuery]);

  const setFilters = (region) => {
    console.log('regionss', region)
    console.log('regionss?.region', filtersValue.region)


    // setRegion(region)
    if (region && filtersValue?.region === region) {
      handleReset();
      setFiltersValue({
        region: '',
        city: '',
        ring: '',
        site: '',
        link: ''
      })
      // setFiltersValue({
      //   region: '',
      //   city: '',
      //   ring: '',
      // })
      // setRegion('')
      // setCity('')
      // setRing('')
    }
    else {
      setFiltersValue({ region })
      if (region) {
        // setApplyFilters(true);
      }
      handleApplyChanges(region)
      // setFiltersValue({
      //   region,
      //   city: '',
      //   ring: '',
      // })
      // if
      // setApplyFilters(true)
    }
    console.log('filtersValue', filtersValue)

  }
  return (
    <>
      <Search sx={{ width: "328px" }}>
        <SearchIconWrapper>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.58341 2.29169C5.55634 2.29169 2.29175 5.55628 2.29175 9.58335C2.29175 13.6104 5.55634 16.875 9.58341 16.875C13.6105 16.875 16.8751 13.6104 16.8751 9.58335C16.8751 5.55628 13.6105 2.29169 9.58341 2.29169ZM1.04175 9.58335C1.04175 4.86592 4.86598 1.04169 9.58341 1.04169C14.3008 1.04169 18.1251 4.86592 18.1251 9.58335C18.1251 14.3008 14.3008 18.125 9.58341 18.125C4.86598 18.125 1.04175 14.3008 1.04175 9.58335ZM16.2248 16.2247C16.4689 15.9807 16.8646 15.9807 17.1087 16.2247L18.7754 17.8914C19.0194 18.1355 19.0194 18.5312 18.7754 18.7753C18.5313 19.0194 18.1356 19.0194 17.8915 18.7753L16.2248 17.1086C15.9807 16.8646 15.9807 16.4688 16.2248 16.2247Z" fill="#999FA7" />
          </svg>
        </SearchIconWrapper>

        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Search>

      {sessionStorage.getItem('selectedTab') !== 'mpbn' &&
        <div className='active_alarms_main'>
          {dataArr && dataArr?.map((item, index) => (
            
            <div className='active_alarms_card_layout' onClick={() => setFilters(item?.name)} style={{ background: filtersValue?.region === '' ? item?.background : filtersValue?.region === item?.name ? item?.background : '#e6e6e6', }}>
              <h3>
                {item?.name}
              </h3>
              <span>
                {item?.count || 0}
              </span>
            </div>))}
        </div>}
      <div className='alerts_list_main' style={
        sessionStorage.getItem('selectedTab') === 'mpbn'
          ? { height: 'calc(100vh - 216px)' }
          : {}
      }>
          <PerfectScrollbar>
            {
              filteredData?.length === 0 ? (
                <p>No Alarms To Show</p>
              ) : loading ? (
                [1, 2, 3, 4].map((_, index) => (
                  <div className="toast-item error" key={index}>
                    <div className="toast error">
                      <Skeleton variant="text" width={200} height={30} />
                      <div style={{ display: 'flex', gap: '5px' }}>
                        <Skeleton variant="text" width={100} height={20} />
                        <Skeleton variant="text" width={100} height={20} />
                      </div>
                      <Skeleton variant="text" width={150} height={20} />
                    </div>
                  </div>
                ))
              ) : (
                filteredData && filteredData?.map((alarm, index) => (
                  <div className="single_alert_card" key={index} onClick={() => setSelectedAlarm(alarm)}>
                    <div className='top_row'>

                      <p className='date_time'>
                        {convertDateToLocalString(alarm?.last_occurred)}
                      </p>
                      <div className='top_row_left'>
                        {/* <p className='city_box'>
                        {alarm?.length ? `${Number(alarm.length).toFixed(2)}KM` : '--KM'}
                      </p> */}
                        <p className='city_box'>{alarm?.city ? alarm.city : alarm?.source_city}</p>
                        <p className='region_box' style={{ color: regionColorsText[alarm?.region], background: regionColorsbackground[alarm?.region] }}>{alarm?.region}</p>
                      </div>
                    </div>
                    <h3>{(sessionStorage.getItem('selectedTab') === 'otn' || sessionStorage.getItem('selectedTab') === 'ipran') ? alarm?.layer_name : replaceUnderscoreWithTo(alarm?.layer_name)}</h3>
                    {sessionStorage.getItem('selectedTab') === 'ftts' && (<span className='alerts_info'>
                      <p style={{ color: "#090C0D" }}>Ring:</p>
                      <p>{alarm?.ring_name}</p>
                    </span>)}
                    {sessionStorage.getItem('selectedTab') === 'mpbn' && (<span className='alerts_info'>
                      <p style={{ color: "#090C0D" }}>Site:</p>
                      <p>{alarm?.source_site_code}</p>
                    </span>)}
                    {/* {sessionStorage.getItem('selectedTab') === 'ftts' && ( */}
                    {sessionStorage.getItem('selectedTab') !== 'mpbn' && (
                      <span className='alerts_info'>
                        <p style={{ color: "#090C0D" }}>Commission Date:</p>
                        <p>
                          {alarm?.commissioned_at ? new Date(alarm.commissioned_at).toLocaleDateString('en-GB') : '--'}
                        </p>
                      </span>)}
                  </div>
                ))
              )
            }
          </PerfectScrollbar>
      </div>
    </>
  );
}
