import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Alert, Tab, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, Grid, Card, CardContent, Pagination } from '@mui/material';
import { Generals } from '../../enums/general.names.enum';
import ManagementTable from './management_table.component';
import axios from 'axios';
import { getAllUsers } from './userManagementFunctions/get_all_users.fucntion';
import { getAllGroups } from './userManagementFunctions/get_all_groups.function';
import { getAllPermissions } from './userManagementFunctions/get_all_permissions.function';
import PermissionDataTable from './permission_management_table.component';
import GroupDataTable from './group_management_table.component';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import ExcelDataModal from './excel_data_modal.component';
import NewUserModal from './new_user_modal.component';
import { downloadExcelSheetFunction } from '../../common/functions/download_excel_sheet..function';
import DownloadIcon from '@mui/icons-material/Download';
import AssignBulkGroupToUserModal from './assign_bulk_group_to_user_modal.component';

const GroupManagement = (props) => {
  const { control } = props;
  const [allUsers, setAllUsers] = useState([])
  const [allUsersData, setAllUsersData] = useState(null)
  const [allGroups, setAllGroups] = useState([])
  const [openUserModal, setOpenUserModal] = useState()
  const [allPermissions, setAllPermissions] = useState([])
  const [openExcelDataModal, setOpenExcelDataModal] = useState(false)
  const [OpenAssignBulkGroupToUserModal,setOpenAssignBulkGroupToUserModal] = useState()

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (control === Generals.user) { fetchUsers() }
    if (control === Generals.group) { fetchGroups() }
    if (control === Generals.permission) { fetchGroups() }
  }, [control]);
  const fetchUsers = async () => {
    const allUser = await getAllUsers();
    console.log('all Users', allUser);
    setAllUsersData(allUser?.response)
    setAllUsers(allUser?.response.allusers);
  };
  const fetchGroups = async () => {
    const allGroup = await getAllGroups();
    console.log('all Groups', allGroup);
    setAllGroups(allGroup);
  };
  const fetchPermissions = async () => {
    const allPermission = await getAllPermissions();
    console.log('all Permissions', allPermission);
    setAllPermissions(allPermission);
  };

  const handleEditClick = () => {

  }
  const handleCloseUserModal = () => {
    setOpenUserModal(false)
  }
 
  const handleAssignBulkGroupToUser =()=>{
    setOpenAssignBulkGroupToUserModal(true)
  }
  const handleCloseAssignBulkGroupToUser=()=>{
    setOpenAssignBulkGroupToUserModal(false)
  }
  return (
    <Box>
      <ExcelDataModal open={openExcelDataModal} fetchUsers={fetchUsers} handleClose={() => setOpenExcelDataModal(false)} />
      <NewUserModal handleClose={handleCloseUserModal} open={openUserModal} fetchUsers={fetchUsers} />
      <AssignBulkGroupToUserModal open={OpenAssignBulkGroupToUserModal} handleClose={handleCloseAssignBulkGroupToUser} users={allUsers} groups={allGroups} fetchUsers={fetchUsers} fetchGroups={fetchGroups}/>
      <Box>
        {control !== Generals.permission ?
          <Grid container alignItems="center" style={{ paddingTop: '0px' }} >
            <Grid item xs={6} sm={1.5} >
              <Card style={{ border: '1px solid #E5E9EB',boxShadow:'none', borderRadius: '0px', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}>
                <CardContent style={{ paddingBottom: '12px' }}>
                  <Typography fontSize={'12px'} fontWeight={400} paddingLeft='3px' color={`rgba(0, 0, 0, 0.50)`} fontStyle={'normal'}>Total</Typography>
                  <Typography fontSize={'28px'} fontWeight={500} color='#121319' fontStyle={'normal'}>{control === Generals.user ? allUsers?.length : allGroups?.length}<span style={{ width: '20px', height: '20px', flexShrink: '0', paddingTop: '2px' }}><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 20V4M12 4L6 10M12 4L18 10" stroke="#0073EE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span></Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={1.5}>
              <Card style={{ border: '1px solid #E5E9EB',borderLeft:'none',boxShadow:'none', borderRadius: '0px' }}>
                <CardContent style={{ paddingBottom: '12px' }}>
                  <Typography fontSize={'12px'} fontWeight={400} paddingLeft='3px' color={`rgba(0, 0, 0, 0.50)`} fontStyle={'normal'}>Active</Typography>
                  <Typography fontSize={'28px'} fontWeight={500} color='#121319' fontStyle={'normal'}>{control === Generals.user ? allUsersData?.activeCount : allGroups?.length}<span style={{ color: 'green', paddingTop: '2px' }}><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 5V19M5 12H19" stroke="#22C55E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span></Typography>
                </CardContent>
              </Card>
            </Grid>
            {control === Generals.user &&
              <Grid item xs={6} sm={1.5}>
                <Card style={{ border: '1px solid #E5E9EB',borderLeft:'none',boxShadow:'none', borderRadius: '0px' }}>
                  <CardContent style={{ paddingBottom: '12px' }}>
                    <Typography fontSize={'12px'} fontWeight={400} paddingLeft='3px' color={`rgba(0, 0, 0, 0.50)`} fontStyle={'normal'}>Inactive</Typography>
                    <Typography fontSize={'28px'} fontWeight={500} color='#121319' fontStyle={'normal'}>{control === Generals.user ? allUsersData?.inActiveCount : 0}<span style={{ color: '#BD1D23', paddingTop: '2px' }}><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                      <path d="M5 12H19" stroke="#BD1D23" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg></span></Typography>
                  </CardContent>
                </Card>
              </Grid>}
            <Grid item xs={6} sm={1.5}>
              <Card style={{ border: '1px solid #E5E9EB',borderLeft:'none',boxShadow:'none', borderRadius: '0px', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}>
                <CardContent style={{ paddingBottom: '12px' }}>
                  <Typography fontSize={'12px'} fontWeight={400} paddingLeft='3px' color={`rgba(0, 0, 0, 0.50)`} fontStyle={'normal'}>Unassigned</Typography>
                  <Typography fontSize={'28px'} fontWeight={500} color='#121319' fontStyle={'normal'}>{control === Generals.user ? allUsersData?.unAssignedCount : allGroups?.length}<span style={{ color: 'red', paddingTop: '2px' }}><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                    <path d="M12 4V20M12 20L6 14M12 20L18 14" stroke="#F1A846" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></span></Typography>
                </CardContent>
              </Card>
            </Grid>
            {control === Generals.user ?
              <Grid item xs={12} sm={6} sx={{ paddingRight: { xs: '0', sm: '1%' }, marginTop: { xs: '0', sm: '-2.5%' }, textAlign: { xs: 'left', sm: 'end' }}}>
                <Button onClick={() => handleAssignBulkGroupToUser()} variant="contained" color="secondary" style={{ marginRight: '8px', backgroundColor: '#FFF', color: '#000' }}
                  startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="#BD1D23"/>
                    </svg>
                  }>
                  Assign Groups
                </Button>
                <Button onClick={() => setOpenExcelDataModal(true)} variant="contained" color="secondary" style={{ marginRight: '8px', backgroundColor: '#FFF', color: '#000' }}
                  startIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM8 13H10.55V16H13.45V13H16L12 9L8 13Z" fill="#BD1D23" />
                    </svg>
                  }>
                  Bulk Upload
                </Button>
                <Button variant="contained" sx={{
                  '&:hover': {
                    borderColor: '#BD1D23',
                    backgroundColor: 'rgba(189, 29, 35, 0.1)', // Optional: Adds a hover effect with a light background
                  },
                }} onClick={() => setOpenUserModal(true)} style={{ backgroundColor: '#BD1D23' }} startIcon={<AddIcon />}>
                  New User
                </Button>
              </Grid> : null}
          </Grid> :
          <>

            <Grid container sx={{ width: '99%' }}>
              <Grid item xs={8} >
                <Typography fontSize={'20px'} fontWeight={500} fontFamily={'Inter'} color={'#000'}>Permission Assignment</Typography>
              </Grid>
              <Grid item xs={4} >
                {/* <Alert variant="filled" severity="warning">
                  This is a filled warning Alert.
                </Alert> */}
              </Grid>
            </Grid>
          </>
        }
        {control === 'user' ? <ManagementTable control={control} users={allUsers} groups={allGroups} fetchUsers={fetchUsers} /> : null}
        {control === Generals.group ? <GroupDataTable control='group' groups={allGroups} fetchGroups={fetchGroups} /> : null}
        {control === Generals.permission ? <PermissionDataTable control='permission' permissions={allGroups} fetchGroups={fetchGroups} /> : null}
      </Box>
    </Box>
  );
};

export default GroupManagement;
