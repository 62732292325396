import React, { useMemo, useState, useEffect } from 'react';
import { Box, Grid, TextField, Typography, createTheme, Button, InputAdornment, Chip, Checkbox } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
    MaterialReactTable,
    useMaterialReactTable
} from "material-react-table";
import dayjs from 'dayjs';
import { downloadExcelSheetFunction } from '../../common/functions/download_excel_sheet..function';
import AssignPermissionsToTenantModal from './assign_permissions_to_tenant_modal';

export default function TenantManagementTable({
    tenants,fetchTenants
}) {
    const validData = Array.isArray(tenants) ? tenants : [];
    console.log('rowData',tenants)

    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [openAssignTenantModal,setOpenAssignTenantModal] = useState();
    const [selectedRowData, setSelectedRowData] = useState(null);
    const handleAssignClick = (rowData) => {
        console.log('fullRow', rowData)
        setSelectedRowData(rowData);
        setOpenAssignTenantModal(true);
      };
      const handleCloseAssignTenantPermissionsModal = () => {
        setOpenAssignTenantModal(false);
        setSelectedRowData(null);
    };
    const handleEditClick = (rowData) => {
        console.log('fullRow', rowData)

    };
    const colorStyles = [
        {
            backgroundColor: '#FFF7E0', // Light yellow
            color: '#D89200', // Dark yellow
            borderColor: '#D89200', // Dark yellow border
        },
        {
            backgroundColor: '#F5F3FF', // Light purple
            color: '#7C3AED', // Dark purple
            borderColor: '#C4B5FD', // Dark purple border
        },
        {
            backgroundColor: '#FEF2F2', // Light red
            color: '#DC2626', // Dark red
            borderColor: '#FCA5A5', // Dark red border
        },
        {
            backgroundColor: '#E0FFF7', // Light green
            color: '#00A692', // Dark green
            borderColor: '#00A692', // Dark green border
        },
        {
            backgroundColor: '#F0F4FF', // Light blue
            color: '#0066CC', // Dark blue
            borderColor: '#0066CC', // Dark blue border
        },
        {
            backgroundColor: '#FFF0F6', // Light pink
            color: '#FF4081', // Dark pink
            borderColor: '#FF4081', // Dark pink border
        },
    ];
    const getGroupColor = (() => {
        const groupColorMap = new Map();
        let styleIndex = 0;
      
        return (groupName) => {
            if (!groupColorMap.has(groupName)) {
                groupColorMap.set(groupName, colorStyles[styleIndex % colorStyles.length]);
                styleIndex += 1;
            }
            return groupColorMap.get(groupName);
        };
      })();
    //   const handleDownload = () => {
    //     downloadExcelSheetFunction(tenants, 'tenants')
    //   }
    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: 'Name',
            size: 120,
            Cell: ({ cell }) => (<span style={{ color: '#24A0ED' }}>{cell?.getValue()}</span>),
        },
        {
            accessorKey: 'permissions',
            header: 'Permissions',
            size: 200,
            Cell: ({ cell }) => (
                <span>
                    {Array.isArray(cell.getValue()) && cell.getValue().length > 0
                        ? cell.getValue().map((group, index) => {
                            const styles = getGroupColor(group.name);
                            return <span style={{
                                background: styles.backgroundColor,
                                color: styles.color,
                                borderColor: styles.borderColor,
                                borderWidth: 1,
                                border: `1px solid ${styles.borderColor}`,
                                padding: '2px 8px',
                                fontSize: '10.5px',
                                fontWeight: '550',
                                marginRight: '6px',
                                borderRadius: '24px',
                            }}>
                                {group.name}
                            </span>
                        })
                        : null}
                </span>
            ),
        },
        {
            accessorKey: 'actions', 
            header: 'Actions',
            size: 150,
            Cell: ({ row }) => (
                <div>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer', marginRight: '16px' }} width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM5.92 19H5V18.08L14.06 9.02L14.98 9.94L5.92 19ZM20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3C17.4 3 17.15 3.1 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63Z" fill="black" fill-opacity="0.54" />
                    </svg> */}
                    <svg  onClick={() => handleAssignClick(row.original)} style={{ cursor: 'pointer', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" fill="black" fill-opacity="0.54" />
                    </svg>
                </div>
            ),
        },
    ], [tenants, selectedUserIds]);

    const table = useMaterialReactTable({
        columns,
        data: validData,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableGrouping: true,
        enableColumnPinning: true,
        enableFacetedValues: true,
        initialState: {
            showColumnFilters: true,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
            sx: {
                '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#BD1D23',
                    color: 'white',
                },
            },
        },
        enableBottomToolbar: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enablePagination: true,
        initialState: { density: 'compact' },
        muiTableContainerProps: { sx: { maxHeight: "calc(100vh - 320px)" } },
        muiTableBodyCellProps: {
            sx: {
                color: "#667085",
                backgroundColor: '#fff',
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: '400',
                lineHeight: "125%",
                height: "36px",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                color: "#344054",
                backgroundColor: '#E8E9E9',
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: '600',
                lineHeight: "125%",
            },
        },
        muiTablePaperProps: {
            sx: {
                boxShadow: 'none',
            },
        },
        // renderTopToolbarCustomActions: ({ table }) => (
        //     <Tooltip title="Download">
        //       <svg style={{ cursor: "pointer" }} onClick={handleDownload} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //         <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M7 10L12 15L17 10" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //         <path d="M12 15V3" stroke="#0000008a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        //       </svg>
        //     </Tooltip>
        //   ),
    });
    return (
        <>
        <AssignPermissionsToTenantModal open={openAssignTenantModal} handleClose={handleCloseAssignTenantPermissionsModal}  rowData={selectedRowData} fetchTenants={fetchTenants}/>
        <div style={{ marginRight: "32px", marginTop: "20px" }}>
            <div className="box" style={{ marginTop: "20px" }}>
                <MaterialReactTable table={table} />
            </div>
        </div>
        </>
    )
}
