import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { borderRadius } from '@mui/system';
import { TextField, Grid, Switch, FormControlLabel, FormGroup ,FormControl, MenuItem, InputLabel, Select, FormHelperText} from '@mui/material';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import apiClient from '../../common/functions/api_axios_instance.function';
import {    } from '@mui/material';
import { PocTypes } from '../../enums/soilTypes.enum';
import { decodeGeometry } from '../../common/functions/decode_geometry_to_geojson.function';
import * as turf from '@turf/turf';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 413,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    //   boxShadow: 24,
    p: 4,
};

export default function PocEstimationModal(props) {
    const pocObjectTemplate = {
        pocType:'',
        soilType:'',
        availability:'',
        cost: '',
        description: '',
    }
    const { open, handleClose,selectRoute } = props;
    const { showBackdrop, hideBackdrop } = useBackdrop();
    const [pocObj, setPocObj] = useState(pocObjectTemplate);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPocObj((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCloseModal = () => {
        setPocObj(pocObjectTemplate)
        handleClose();
    };


    const handleSubmitPoc = () => {
        let geoData  =  selectRoute.geometry.coordinates
        
        
        showBackdrop()
        console.log('pocObj===>',pocObj)

        apiClient.post(`/routes/additionalCosts/${props.selectedRouteAdditional}`, 
            {...pocObj,start_location:`${geoData[0][0]},${geoData[0][1]}`,
            end_location:`${geoData[geoData.length-1][0]},${geoData[geoData.length-1][1]}`,
            geoJSON: {
                type: "LineString",
                coordinates:geoData
              },
            length:turf.length(selectRoute,{units: 'meters'}).toFixed(3)
            })
            .then(response => {
                handleCloseModal()
                hideBackdrop()
                handleClose()
                props.setRemoveMarkersSubRoute(true)
                props.setSubmitAdditional(true)
                // notify('success', 'Successfully')
            }
            )
            .catch(err => {
                notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
                hideBackdrop()
            });
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" style={{
                        // fontFamily: 'Roboto',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal'
                    }}>
                        Fiber Deployment Cost
                    </Typography>
                    <Grid container spacing={2} paddingTop={'20px'}>
                    <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                <Select
                                    name="pocType"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pocObj.pocType}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                    label='User Type'
                                    required
                                    data-testId='userModal-userType-1'
                                >
                                    <MenuItem value="">
                                        <em>Please Select</em>
                                    </MenuItem>
                                    <MenuItem value={PocTypes.Row}>{PocTypes.Row}</MenuItem>
                                    <MenuItem value={PocTypes.soil}>{PocTypes.soil}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {pocObj.pocType === PocTypes.soil &&
                        <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-label">Soil Type</InputLabel>
                                <Select
                                    name="soilType"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pocObj.soilType}
                                    onChange={handleChange}
                                    sx={{ width: '100%' }}
                                    label='User Type'
                                    required
                                    data-testId='userModal-userType-1'
                                >
                                    <MenuItem value="">
                                        <em>Please Select</em>
                                    </MenuItem>
                                    <MenuItem value="Normal">Normal</MenuItem>
                                    <MenuItem value="Rocky">Rocky</MenuItem>
                                    <MenuItem value="Asphalt">Asphalt</MenuItem>
                                    <MenuItem value="Bridge Attachment">Bridge Attachment</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>}
                        <Grid item xs={12}>
                            <TextField id="standard-basic" required value={pocObj.cost} onChange={handleChange} name='cost' label="Cost Per Meter" variant="outlined" sx={{ width: '350px' }} />
                        </Grid>
                       
                        {pocObj.pocType === PocTypes.Row &&
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Availability %"
                                multiline
                                rows={2}
                                name='availability'
                                value={pocObj.availability}
                                onChange={handleChange}
                                sx={{ width: '350px' }}
                            />
                        </Grid>}
                        <Grid container spacing={2} style={{ marginLeft: '11px', marginTop: '20px' }}>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    backgroundColor: '#BD1D23', width: '160px',
                                    '&:hover': {
                                        backgroundColor: '#BD1D23',
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#E57373',
                                        color: '#fff',
                                    },
                                }}
                                    onClick={handleSubmitPoc}
                                >Check</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{
                                    color: '#000', backgroundColor: 'white', width: '160px', '&:hover': {
                                        borderColor: '#BD1D23',
                                        backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                    },
                                }} onClick={() => handleCloseModal()}>Cancel</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}