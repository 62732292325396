import apiClient from '../../../common/functions/api_axios_instance.function';
import { logout } from '../../../common/functions/log_out.function';
export const getCompanies = async () => {
try{ 
  const resp = await apiClient.get(`/users/getAllCompanies`);

  return (resp.data);

} catch (error) {
    console.error('Error fetching users:', error);
    if(error.response.status === 401) {logout()}
  }
}