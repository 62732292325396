import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem, Stack, CircularProgress, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import { notify } from '../../common/functions/react-toastify.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import apiClient from '../../common/functions/api_axios_instance.function';

export default function AddNewTenantModal(props) {
    const { handleClose, open, fetchTenants } = props;

    const tenantbjectTemplate = {
        name: '',
        password: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        billingType: '',
        file: ''
    };

    const [tenantObj, setTenantObj] = useState(tenantbjectTemplate);
    const [errors, setErrors] = useState({});
    const { showBackdrop, hideBackdrop } = useBackdrop();

    useEffect(() => {
        // Reset the form and errors whenever the modal is opened or closed
        if (open) {
            setTenantObj(tenantbjectTemplate);
            setErrors({});
        }
    }, [open]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTenantObj({ ...tenantObj, [name]: value });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
    console.log('123',selectedFile); // Add this line to verify
    setTenantObj({ ...tenantObj, file: selectedFile });
        // setTenantObj({ ...tenantObj, file: e.target.files[0] });
    };

    const validateEmail = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // const handleSubmit = () => {
    //     let valid = true;
    //     const newErrors = {};

    //     // Validate required fields
    //     if (!tenantObj.name.trim()) {
    //         newErrors.name = true;
    //         valid = false;
    //     }
    //     if (!tenantObj.password.trim()) {
    //         newErrors.password = true;
    //         valid = false;
    //     }

    //     // Validate email format if provided
    //     if (tenantObj.contactEmail && !validateEmail(tenantObj.contactEmail)) {
    //         newErrors.contactEmail = true;
    //         valid = false;
    //     }

    //     setErrors(newErrors);

    //     if (valid) {
    //         showBackdrop()
    //         apiClient.post(`/tenant/createTenant`, tenantObj)
    //             .then(response => {
    //                 handleCloseModal()
    //                 fetchTenants()
    //                 hideBackdrop()
    //                 handleClose()
    //                 notify('success', 'Group Created Successfully')
    //             }
    //             )
    //             .catch(err => {
    //                 notify('error', err?.response?.data?.message ? err?.response?.data?.message : err.message ? err.message : 'Something went wrong')
    //                 hideBackdrop()
    //             });
    //     }
    // };
   
   
    const handleSubmit = () => {
        let valid = true;
        const newErrors = {};
    
        // Validate required fields
        if (!tenantObj.name.trim()) {
            newErrors.name = true;
            valid = false;
        }
        if (!tenantObj.password.trim()) {
            newErrors.password = true;
            valid = false;
        }
    
        // Validate email format if provided
        if (tenantObj.contactEmail && !validateEmail(tenantObj.contactEmail)) {
            newErrors.contactEmail = true;
            valid = false;
        }
    
        setErrors(newErrors);
    
        if (valid) {
            showBackdrop();
    
            // Prepare FormData to handle file uploads
            const formData = new FormData();
            
            // Append all fields, including the file
            Object.keys(tenantObj).forEach(key => {
                formData.append(key, tenantObj[key]);
            });
    
            // API call using FormData
            apiClient.post(`/tenant/createTenant`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then(response => {
                handleCloseModal();
                fetchTenants();
                hideBackdrop();
                handleClose();
                notify('success', 'Tenant Created Successfully');
            })
            .catch(err => {
                notify('error', err?.response?.data?.message || err.message || 'Something went wrong');
                hideBackdrop();
            });
        }
    };
    
    const handleRemoveFile = () => {
        setTenantObj({ ...tenantObj, file: null });
    };
    const handleCloseModal = () => {
        setTenantObj(tenantbjectTemplate)
        handleClose();
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    width: ['90%', '80%', '60%'], // Responsive width
                    maxHeight: '90vh',
                    overflowY: 'auto' // Adds scrolling for overflow content
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        letterSpacing: '2px',
                        background: 'linear-gradient(90deg, #BD1D23, #FF5733)',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        animation: 'gradientMove 3s ease-in-out infinite',
                    }}
                >
                    Add Tenant
                </Typography>

                <form noValidate autoComplete="off">
                    <TextField
                        fullWidth
                        label="Tenant Name"
                        name="name"
                        value={tenantObj.name}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        error={!!errors.name}
                        helperText={errors.name ? "Tenant Name is required" : ''}
                    />

                    <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        value={tenantObj.password}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        error={!!errors.password} // Add error prop
                        helperText={errors.password ? "Password is required" : ''} // Display error message
                    />

                    <TextField
                        fullWidth
                        label="Contact Name"
                        name="contactName"
                        value={tenantObj.contactName}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Contact Email"
                        name="contactEmail"
                        type="email"
                        value={tenantObj.contactEmail}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        error={!!errors.contactEmail} // Add error prop
                        helperText={errors.contactEmail ? "Enter a valid email" : ''} // Display error message if email is invalid
                    />

                    <TextField
                        fullWidth
                        label="Contact Phone"
                        name="contactPhone"
                        value={tenantObj.contactPhone}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        label="Billing Type"
                        name="billingType"
                        value={tenantObj.billingType}
                        onChange={handleChange}
                        variant="outlined"
                        margin="normal"
                        multiline // Enables the text area functionality
                        rows={4} // Number of rows for the text area
                    />
                    <Box
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            padding: '20px',
                            textAlign: 'center',
                            marginTop: '20px',
                            cursor: 'pointer',
                            position: 'relative',
                            backgroundColor: '#f9f9f9'
                        }}
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        <CloudUploadIcon color="action" sx={{ fontSize: 48 }} />
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            Click or drag file to this area to upload
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Upload file supported formats .pdf
                        </Typography>

                        <input
                            id="fileInput"
                            type="file"
                            accept=".pdf"  // Restrict file type to PDF
                            hidden
                            onChange={handleFileChange}
                        />

                    </Box>
                    {tenantObj.file && (
                        <Box
                            sx={{
                                mt: 2,
                                p: 2,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: '1px solid #ccc',
                                borderRadius: '4px'
                            }}
                        >
                            <Typography>{tenantObj.file?.name}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {/* {uploading ? (
                                <CircularProgress size={24} />
                            ) : (
                                <IconButton onClick={handleRemoveFile}>
                                    <CancelIcon />
                                </IconButton>
                            )} */}
                            </Box>
                        </Box>
                    )}
                    <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                        <Button
                            variant="contained"
                            fullWidth
                            sx={{
                                backgroundColor: '#BD1D23',
                                '&:hover': {
                                    backgroundColor: '#9c1a1e'
                                },
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            fullWidth
                            sx={{
                                color: '#000',
                                backgroundColor: 'white',
                                '&:hover': {
                                    borderColor: '#BD1D23',
                                    backgroundColor: 'rgba(189, 29, 35, 0.1)',
                                },
                            }}
                        >
                            CANCEL
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}
