import { Box, CircularProgress, TextField, Button, Select, MenuItem, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export default function RouteAnalysisForm({routes}) {
    const navigate = useNavigate();    
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        comparisonName: '',
        startLocation: '',
        endLocation: '',
        route1: '',
        route2: ''
    });
    const [loading, setLoading] = useState(false);
    const [startLoctions, setStartLoctions] = useState([]);
    const [endLocations, setEndLocations] = useState([]);
    const [locationsRoutes, setLocationsRoutes] = useState([]);
    const [routeObjs, setRouteObjs] = useState([]);
    const [selectedRouteObj, setSelectedRouteObj] = useState({});
    
    useEffect(()=>{
        let routesLoc = []
        console.log('e',formData.startLocation!=''&&formData.endLocation!='')
        routes.forEach(e => {
            console.log('24e',e.start_location==formData.startLocation&&e.end_location==formData.endLocation)
            
            if(formData.startLocation!=''&&formData.endLocation!=''&&
                (e.start_location==formData.startLocation&&e.end_location==formData.endLocation)){
                routesLoc.push(e.routeName)
            }            
        });
        console.log('routesLoc==>',routesLoc)
        setLocationsRoutes(routesLoc)
    },[routes, formData])
    useEffect(()=>{
        let strtloc = []
        let endloc = []
        routes.forEach(e => {
            if(!strtloc.includes(e.start_location)){
                strtloc.push(e.start_location)
            }
            if(!endloc.includes(e.end_location))
            endloc.push(e.end_location)
        });
        setStartLoctions(strtloc)
        setEndLocations(endloc)
    },[routes])

    useEffect(()=>{
        let routelocs = []        
        routes.forEach(e => {
            let obj={}
            obj.startLocation = e.start_location
            obj.endLocation = e.end_location
            let index = routelocs.findIndex(i =>i.startLocation==e.start_location&&i.endLocation==e.end_location);
            if(index>=0){
                routelocs[index].routes.push(e)
            }
            else{
                obj.routes = [e]
                routelocs.push(obj)
            }            
        });
        console.log('routelocs===>',routelocs)
        setRouteObjs(routelocs)
    },[routes])

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (route) => {
        console.log('e==>',route)        
        setSelectedRouteObj(route)
        setLoading(true);
        setTimeout(() => {
            // setSubmitted(true);  // Simulating a delay, such as a server request
            setLoading(false);
            navigate(`/routeAnalysisDetails/${route.startLocation}/${route.endLocation}`);
        }, 1500);
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',  // Form width
                    backgroundColor: 'white',  // Form background
                    padding: 3,
                    borderRadius: 2,  // Rounded corners
                    boxShadow: 2,     // Box shadow for styling
                    margin: '0 auto'  // Center the form on the page
                }}
            >
                {!submitted ? (

                    // <form onSubmit={handleSubmit}>
                    //     <TextField
                    //         fullWidth
                    //         label="Comparison Name"
                    //         variant="outlined"
                    //         sx={{ mb: 2 }}
                    //         name="comparisonName"
                    //         value={formData.comparisonName}
                    //         onChange={handleInputChange}
                    //     />

                    //     <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    //         <FormControl fullWidth>
                    //             <InputLabel id="dropdown-1-label">Start Location</InputLabel>
                    //             <Select
                    //                 labelId="dropdown-1-label"
                    //                 label="Start Location"
                    //                 name="startLocation"
                    //                 value={formData.startLocation}
                    //                 onChange={handleInputChange}
                    //             >
                    //                 {startLoctions.map((i)=>
                    //                     <MenuItem value={i}>{i}</MenuItem>
                                        
                    //                 )}
                    //             </Select>
                    //         </FormControl>

                    //         <FormControl fullWidth>
                    //             <InputLabel id="dropdown-2-label">End Location</InputLabel>
                    //             <Select
                    //                 labelId="dropdown-2-label"
                    //                 label="End Location"
                    //                 name="endLocation"
                    //                 value={formData.endLocation}
                    //                 onChange={handleInputChange}
                    //             >
                    //                     {endLocations.map((i)=>
                    //                     <MenuItem value={i}>{i}</MenuItem>
                                        
                    //                 )}
                    //             </Select>
                    //         </FormControl>
                    //     </Box>

                    //     <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    //         <FormControl fullWidth>
                    //             <InputLabel id="dropdown-1-label">Route 1</InputLabel>
                    //             <Select
                    //                 labelId="dropdown-1-label"
                    //                 label="Route 1"
                    //                 name="route1"
                    //                 value={formData.route1}
                    //                 onChange={handleInputChange}
                    //             >{locationsRoutes.map(i=>
                    //                 <MenuItem value={i}>{i}</MenuItem>
                    //             )}                                    
                    //             </Select>
                    //         </FormControl>

                    //         <FormControl fullWidth>
                    //             <InputLabel id="dropdown-2-label">Route 2</InputLabel>
                    //             <Select
                    //                 labelId="dropdown-2-label"
                    //                 label="Route 2"
                    //                 name="route2"
                    //                 value={formData.route2}
                    //                 onChange={handleInputChange}
                    //             >
                    //                 {locationsRoutes.map(i=>
                    //                 <MenuItem value={i}>{i}</MenuItem>
                    //             )}   
                    //             </Select>
                    //         </FormControl>
                    //     </Box>

                    //     <Button
                    //         fullWidth
                    //         variant="contained"
                    //         sx={{ backgroundColor: '#3A474F', fontSize: '16px' }}
                    //         type="submit"
                    //         disabled={Object.values(formData).some(value => value === '')||loading}
                    //     >
                    //         {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                    //     </Button>
                    // </form>
                    <div  
                    style={{height:"80%"}}
                  >
                 
                    {/* Drawer Content (Dynamic Routes) */}
                    <div className="active_alarms_main">
                      <div className="active_alarms_card_layout">
                        <h2>{'Routes'}</h2>
                      </div>
                    </div>
          
                    <Divider />
          
                    {/* Alerts List */}
                    <div className="alerts_list_main"
                     style={{ height: '60vh', width: '100%'}}
                    >
                      <PerfectScrollbar>
                      {routeObjs.map((route, index) => (
                          <div className="single_alert_card" 
                          style={{width:"98%"}}
                           //onClick={() => setSelectedNav(route.id)}
                          >
                            <Box>
                            <TableContainer component={Paper}>
                                <Table aria-label="route comparison table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Start Location</TableCell>
                                            <TableCell>End Location</TableCell>
                                            <TableCell>Cost</TableCell>
                                            <TableCell>Length</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {route.routes.map(i=>

                                        <TableRow>
                                            <TableCell>{i.routeName}</TableCell>
                                            <TableCell>({i.start_location})</TableCell>
                                            <TableCell>({i.end_location})</TableCell>
                                            <TableCell>{i.totalCost}</TableCell>
                                            <TableCell>{i.length}</TableCell>
                                        </TableRow>

                                    )
                                        }
                                    </TableBody>
                                </Table>
                                <Box sx={{ margin: '1%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#3A474F', fontSize: '16px' }}
                                        onClick={()=>handleSubmit(route)}
                                        disabled={route.routes.length < 2}
                                    >
                                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Compare'}
                                    </Button>
                                </Box>
                            </TableContainer>
                        </Box>
                          {/* <div className="top_row">
                            <p className="date_time">{index+1}</p>
                          </div>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>id:</p>
                            <p style={{ color: '#090C0D' }}>{route.id}</p>
                          </span>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>name:</p>
                            <p style={{ color: '#090C0D' }}>{route.routeName}</p>
                          </span>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>start_location:</p>
                            <p style={{ color: '#090C0D' }}>{route.start_location}</p>
                          </span>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>end_location:</p>
                            <p style={{ color: '#090C0D' }}>{route.end_location}</p>
                          </span>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>length:</p>
                            <p style={{ color: '#090C0D' }}>{route.length}</p>
                          </span>
                          <span className="alerts_info">
                            <p style={{ color: '#090C0D' }}>totalCost:</p>
                            <p style={{ color: '#090C0D' }}>{route.totalCost}</p>
                          </span> */}
                        </div>
                      ))}
                      
                      </PerfectScrollbar>
                    </div>
                  </div>
                ) : (
                    <Box sx={{ overflowX: 'auto' }} style={{height:"80vh"}}>
                    <TableContainer component={Paper} style={{width:"100%"}}>
                      <Table aria-label="route comparison table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell colSpan={3} align="center">Origin</TableCell>
                            <TableCell colSpan={3} align="center">Destination</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell />
                            <TableCell colSpan={3} align="center">{selectedRouteObj.startLocation}</TableCell>
                            <TableCell colSpan={3} align="center">{selectedRouteObj.endLocation}</TableCell>
                          </TableRow>
                          <TableRow>                              
                          {selectedRouteObj.routes.map(i=>{
                            return(
                                <TableCell colSpan={3} align="center" sx={{ borderRight: '2px solid #ddd' }}>{i.routeName}</TableCell>                                
                              )
                            }
                            
                          )}
                          </TableRow>                              
                          <TableRow>
                            <TableCell >Length</TableCell>
                            <TableCell>500 km</TableCell>
                            <TableCell />
                            <TableCell sx={{ borderRight: '2px solid #ddd' }}/>
                            <TableCell >400 km</TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {['Normal Soil', 'Rocky', 'Bridge Attachment', 'RoW 1 (50%)', 'RoW 2'].map((terrain, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ width:"13%" }}>{terrain}</TableCell>
                              <TableCell sx={{ width:"13%" }}>xxx</TableCell>
                              <TableCell sx={{ width:"13%" }}>xxx</TableCell>
                              <TableCell sx={{ borderRight: '2px solid #ddd', width:"13%"}}>xxx</TableCell>
                              <TableCell sx={{ width:"13%" }}>xxx</TableCell>
                              <TableCell sx={{ width:"13%" }}>xxx</TableCell>
                              <TableCell sx={{ width:"13%" }}>{terrain === 'RoW 1 (50%)' || terrain === 'RoW 2' ? '100%' : 'xxx'}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Total Cost</TableCell>
                            <TableCell>xxx</TableCell>
                            <TableCell />
                            <TableCell sx={{ borderRight: '2px solid #ddd' }}/>
                            <TableCell>xxx</TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                          <TableRow>
                            <TableCell>Availability</TableCell>
                            <TableCell>50%</TableCell>
                            <TableCell />
                            <TableCell sx={{ borderRight: '2px solid #ddd' }}/>
                            <TableCell>100%</TableCell>
                            <TableCell />
                            <TableCell />
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
              

                )}
            </Box>
        </>
    );
}
