import React, { useState, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import { getRoutesForPoc,getAdditionalCostsForPoc,getRouteOneAdditionlCount,getRouteOneForPoc,getRouteOneAdditionlForPoc} from './functions/get_routes_for_poc.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import { notify } from '../../common/functions/react-toastify.function';
import { decodeGeometry } from '../../common/functions/decode_geometry_to_geojson.function';
import SideNavPOC from './sideNavPOC/SideNavPOC';
import axios from 'axios';
import * as turf from '@turf/turf';

mapboxgl.accessToken = 'pk.eyJ1IjoidXNtYW4tZ2hhbmkiLCJhIjoiY2xoaGNqODllMGFkZTNtcHIxMXlqcGUwdCJ9.430W3OsMNhVeWxBNktOutA';  // Replace with your access token

const MapWithDirections = ({ routes,setSelectedRoute, selectedRoute, selectedRouteAdditional, 
  setSelectedRouteAdditional,setTotalRouteCost,routeSelection,setRouteSelection,mapMarkers,
  setMapMarkers,removeMarkersSubRoute, setRemoveMarkersSubRoute,submitAdditional,setSubmitAdditional }) => {
  const [map, setMap] = useState(null);
  const [selectedNav,setSelectedNav] = useState(null)
  const [oldSelectedNav,setOldSelectedNav] = useState(null)

  const { showBackdrop, hideBackdrop } = useBackdrop();

  useEffect(() => {
    // Initialize map instance
    const initializeMap = () => {
      const mapInstance = new mapboxgl.Map({
        container: 'map', // ID of the HTML element
        style: 'mapbox://styles/mapbox/standard',//'mapbox://styles/mapbox/streets-v11',
        center: [69.3451,30.3753], // Initial center point (e.g., New York)
        zoom: 5,
      });
      // 69.3451,30.3753
       mapInstance.addControl(new mapboxgl.NavigationControl());

      setMap(mapInstance); // Set the map instance in the state

      // Add routes on map load
      mapInstance.on('load', () => {
        // First, handle the selected route (if any)
        if (selectedRoute) {
          const routeGeoJSON = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: decodeGeometry(selectedRoute?.geometry), // Decode the selected route geometry
            },
          };

          if (!mapInstance.getSource('route')) {
            mapInstance.addSource('route', {
              type: 'geojson',
              data: routeGeoJSON,
            });

            mapInstance.addLayer({
              id: 'route-layer',
              type: 'line',
              source: 'route',
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#008000',
                'line-width': 5,
              },
            });
          }
        }
   
          
            
        // const fetchRoutes = async () => {
        //   try {
        //     let routesData = await getRoutesForPoc();
        //     routesData.forEach((route, index) => {
        //       console.log(route)
        //       const routeGeoJSON = {
        //         type: 'Feature',
        //         geometry: {
        //           type: 'LineString',
        //           coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
        //         },
        //       };

        //       // Add a larger hitbox layer for the route (invisible, but larger clickable area)
        //       // Add an invisible hitbox layer for the route
        //       if (!mapInstance.getSource(`route-hitbox-${index}`)) {
        //         mapInstance.addSource(`route-hitbox-${index}`, {
        //           type: 'geojson',
        //           data: routeGeoJSON,
        //         });

        //         mapInstance.addLayer({
        //           id: `route-hitbox-layer-${index}`,
        //           type: 'line',
        //           source: `route-hitbox-${index}`,
        //           layout: {
        //             'line-join': 'round',
        //             'line-cap': 'round',
        //           },
        //           paint: {
        //             'line-color': 'transparent', // Make it invisible
        //             'line-width': 5, // Increase the width for the hitbox
        //           },
        //         });

        //       }
        //       // Check if source for the route already exists
        //       if (!mapInstance.getSource(`route-${index}`)) {
                
        //         mapInstance.addSource(`route-${index}`, {
        //           type: 'geojson',
        //           data: routeGeoJSON,
        //         });
    
        //         mapInstance.addLayer({
        //           id: `route-layer-${index}`,
        //           type: 'line',
        //           source: `route-${index}`,
        //           layout: {
        //             'line-join': 'round',
        //             'line-cap': 'round',
        //           },
        //           paint: {
        //             'line-color': "#fc6f03",
        //             'line-width': 5,
        //           },
        //         });
        //       }

            
        //   // Add hover effect for cursor pointer
        //     mapInstance.on('mouseenter', `route-hitbox-layer-${index}`, () => {
        //       mapInstance.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer on hover
        //     });

        //     // Reset cursor when mouse leaves the route
        //       mapInstance.on('mouseleave', `route-hitbox-layer-${index}`, () => {
        //       mapInstance.getCanvas().style.cursor = ''; // Reset cursor style
        //     });
        //       mapInstance.on('click', `route-hitbox-layer-${index}`, (e) => {
        //         setSelectedRouteAdditional(route.id)
        //         setTotalRouteCost(route.totalCost)
        //         setRouteSelection(false)
        //         console.log(`Route clicked: ${route.id}`);
        //         const clickedPoint = e.lngLat; // Get clicked point's coordinates
        //            console.log("markers===>",mapMarkers)
        //            setMapMarkers((prevState)=>{
        //             console.log("markers===>",prevState)

        //             if (prevState.length >= 2) {
        //               // If two markers are already present, clear them
        //               prevState.forEach((m) => m.marker.remove());
        //               console.log("====markers====",prevState)
        //               return [];
        //             }
        //           else if (prevState.length < 2) {
        //             // Create a new marker
        //             const marker = new mapboxgl.Marker()
        //               .setLngLat([clickedPoint.lng, clickedPoint.lat])
        //               .addTo(mapInstance);
        //             console.log("here")
        //             // Update markers state
        //             return [...prevState, { lng: clickedPoint.lng, lat: clickedPoint.lat, marker }];
  
        //             // If we have two markers, fetch the route between them
        //             if (prevState.length === 1) {
        //               fetchRouteBetweenMarkers(prevState[0], { lng: clickedPoint.lng, lat: clickedPoint.lat });
        //             }
        //           }
  
        //            })
        //       //  new mapboxgl.Marker()
        //       //   .setLngLat([clickedPoint.lng, clickedPoint.lat])
        //       //   .addTo(mapInstance);
        //       });
          
        //     });
        //     let additional =  await getAdditionalCostsForPoc()
        //     additional.response.forEach((route,index)=>{
        //       const routeGeoJSON = {
        //         type: 'Feature',
        //         geometry: {
        //           type: 'LineString',
        //           coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
        //         },
        //       };
        //       if (!mapInstance.getSource(`additional-route-${index}`)) {
                
        //         mapInstance.addSource(`additional-route-${index}`, {
        //           type: 'geojson',
        //           data: routeGeoJSON,
        //         });
    
        //         mapInstance.addLayer({
        //           id: `additional-route-layer-${index}`,
        //           type: 'line',
        //           source: `additional-route-${index}`,
        //           layout: {
        //             'line-join': 'round',
        //             'line-cap': 'round',
        //           },
        //           paint: {
        //             'line-color': "#000000",
        //             'line-width': 5,
        //           },
        //         });
        //       }

        //     })
        //   } catch (error) {
        //     console.error('Error fetching routes:', error);
        //   }
        // };
        // fetchRoutes()
        // mapInstance.on('click', `route-layer`, (e) => {
        //   setSelectedRouteAdditional(null)
        //   setTotalRouteCost(null)
        //   setRouteSelection(false)
         
        // });
      });
    


          // Deselect route on click outside the route layers
          // mapInstance.on('click', (e) => {
          //   // Check if the click is inside any of the route layers
          //   const features = mapInstance.queryRenderedFeatures(e.point, { layers: ['route-layer', 'route-hitbox-layer'] });
          //   console.log('selectedRouteAdditional===>',selectedRouteAdditional)

          //   if (!features.length) {
          //     // If no features (routes) are clicked, set selectedRoute to null
          //     setSelectedRoute(null);
          //     // setSelectedRouteAdditional(null);
          //     // setTotalRouteCost(null);
              
          //   }
          // });
        
    
    };

    if (!map) initializeMap(); // Initialize the map only once

  }, [map, selectedRoute, routeSelection]); 
  const[markers,setMarkers] = useState([])
  const reomvalNewRouteCreation = async() =>{
    await removePreviousLayersAndSources()
    setOldSelectedNav(null)
    setSelectedNav(null)
  }
  useEffect(() => {
    
    if (map) {
      // Create a reference for the directions control
      const handleMapClick = (e) => {
        const { lng, lat } = e.lngLat;          
        // Update the markers state
        setMarkers((prevMarkers) => {
          let isSamePoint = prevMarkers.length > 0 &&
        prevMarkers[prevMarkers.length - 1].lng === lng &&
          prevMarkers[prevMarkers.length - 1].lat === lat;
          if(isSamePoint){
            return prevMarkers
          }
        const newMarker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(map);
  
          const updatedMarkers = [...prevMarkers, { lng, lat, marker: newMarker }];
          
          if (prevMarkers.length >= 1) {
            console.log('adasasd===>',prevMarkers)
            getDirections(updatedMarkers);
          }
        // Update the colors of the markers based on their position
        updatedMarkers.forEach((marker, index) => {
          if (index == 0 ) {
            let elements = marker.marker.getElement()
            let svg = elements.querySelector('svg')
            let path = svg.querySelector('path')
            path.setAttribute('fill', 'red');
          } 
          else if (index == updatedMarkers.length - 1) {
            // Make first and last markers red
            let elements = marker.marker.getElement()
            let svg = elements.querySelector('svg')
            let path = svg.querySelector('path')
            path.setAttribute('fill', 'green');


          } else {
            // Make other markers blue
            let elements = marker.marker.getElement()
            let svg = elements.querySelector('svg')
            let path = svg.querySelector('path')
            path.setAttribute('fill', 'blue');
          }
        });
        
        return updatedMarkers;
        }
      );
    
    }
      if (routeSelection) {
        map.on('click', handleMapClick);
        reomvalNewRouteCreation()
      } else {
        // Disable placing markers
        map.off('click', handleMapClick);
        // Remove the directions control if it exists        
        setMarkers((prevMarkers) =>{ 
          prevMarkers.forEach((m) => m.marker.remove());
          return []
          });
           if (map.getLayer('selected-route')) {
                map.removeLayer('selected-route');
                map.removeSource('selected-route');
              }     
              setAlternativeRoutes([])
        if (map.getLayer('route')) {
          map.removeLayer('route');
          map.removeSource('route');
        }

        
      }

      // Cleanup function to remove the control when the component unmounts or routeSelection changes
      return () => {
        setMarkers((prevMarkers) =>{ 
          prevMarkers.forEach((m) => m.marker.remove());
          return []
          });
          if (map.getLayer('selected-route')) {
            map.removeLayer('selected-route');
            map.removeSource('selected-route');
          }     
          setAlternativeRoutes([])
        if (map.getLayer('route')) {
          map.removeLayer('route');
          map.removeSource('route');
        }

        // Disable placing markers
        map.off('click', handleMapClick);
      };
    }
  }, [map, routeSelection, setSelectedRoute]);
 function createMarkerIcon(color) {
  return `data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <circle cx="15" cy="15" r="12" fill="${color}" />
          </svg>`;
}
useEffect(()=>{
  if(removeMarkersSubRoute){
    setMapMarkers((prevState)=>{
      prevState.forEach((m) => m.marker.remove());
      return [];
    })
    setRemoveMarkersSubRoute(false)
  }
  
},[removeMarkersSubRoute])
const fetchRoutes = async () => {
  try {
    if(!map) return
    if(!selectedNav) return
    console.log(selectedNav)
    const mapInstance = map
    let routesData = await getRouteOneForPoc(selectedNav);
    map.flyTo({
      center: [routesData[0].geoJSON.coordinates[0][0], routesData[0].geoJSON.coordinates[routesData[0].geoJSON.coordinates.length - 1][1]],
      zoom: 10,
      duration: 2000, // Adjust duration for smoother transition
    });
    routesData.forEach((route, index) => {
      console.log(route)
      const routeGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
        },
      };

      // Add a larger hitbox layer for the route (invisible, but larger clickable area)
      // Add an invisible hitbox layer for the route
      if (!mapInstance.getSource(`route-hitbox-${index}`)) {
        mapInstance.addSource(`route-hitbox-${index}`, {
          type: 'geojson',
          data: routeGeoJSON,
        });

        mapInstance.addLayer({
          id: `route-hitbox-layer-${index}`,
          type: 'line',
          source: `route-hitbox-${index}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': 'transparent', // Make it invisible
            'line-width': 5, // Increase the width for the hitbox
          },
        });

      }
      // Check if source for the route already exists
      if (!mapInstance.getSource(`route-${index}`)) {
        
        mapInstance.addSource(`route-${index}`, {
          type: 'geojson',
          data: routeGeoJSON,
        });

        mapInstance.addLayer({
          id: `route-layer-${index}`,
          type: 'line',
          source: `route-${index}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': "#fc6f03",
            'line-width': 5,
          },
        });
      }

    
  // Add hover effect for cursor pointer
    mapInstance.on('mouseenter', `route-hitbox-layer-${index}`, () => {
      mapInstance.getCanvas().style.cursor = 'pointer'; // Change cursor to pointer on hover
    });

    // Reset cursor when mouse leaves the route
      mapInstance.on('mouseleave', `route-hitbox-layer-${index}`, () => {
      mapInstance.getCanvas().style.cursor = ''; // Reset cursor style
    });
      mapInstance.on('click', `route-hitbox-layer-${index}`, (e) => {
        setSelectedRouteAdditional(route.id)
        setTotalRouteCost(route.totalCost)
        setRouteSelection(false)
        console.log(`Route clicked: ${route.id}`);
        const clickedPoint = e.lngLat; // Get clicked point's coordinates
           console.log("markers===>",mapMarkers)
           setMapMarkers((prevState)=>{
            console.log("markers===>",prevState)

            if (prevState.length >= 2) {
              const isSamePoint = prevState.length > 0 &&
              prevState[prevState.length - 1].lng === clickedPoint.lng &&
              prevState[prevState.length - 1].lat === clickedPoint.lat;

              if (isSamePoint) {
                // If the new point is the same as the previous one, return the existing array (no new marker)
                console.log("Marker is on the same point, not adding a new one.");
                return prevState;
              }

              // If two markers are already present, clear them
              prevState.forEach((m) => m.marker.remove());
              console.log("====markers====",prevState)
              return [];
            }
          else if (prevState.length < 2) {
            // Create a new marker
            const isSamePoint = prevState.length > 0 &&
            prevState[prevState.length - 1].lng === clickedPoint.lng &&
            prevState[prevState.length - 1].lat === clickedPoint.lat;

          if (isSamePoint) {
            // If the new point is the same as the previous one, return the existing array (no new marker)
            console.log("Marker is on the same point, not adding a new one.");
            return prevState;
          }

            const marker = new mapboxgl.Marker()
              .setLngLat([clickedPoint.lng, clickedPoint.lat])
              .addTo(mapInstance);
            console.log("here")
            // Update markers state
            return [...prevState, { lng: clickedPoint.lng, lat: clickedPoint.lat, marker }];

            // If we have two markers, fetch the route between them
            if (prevState.length === 1) {
              fetchRouteBetweenMarkers(prevState[0], { lng: clickedPoint.lng, lat: clickedPoint.lat });
            }
          }

           })
      //  new mapboxgl.Marker()
      //   .setLngLat([clickedPoint.lng, clickedPoint.lat])
      //   .addTo(mapInstance);
      });
  
    });
    setOldSelectedNav(selectedNav)

    let additional =  await getRouteOneAdditionlForPoc(selectedNav)
    console.log('additional===>',additional)
    additional.forEach((route,index)=>{
      const routeGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
        },
      };
      if (!mapInstance.getSource(`additional-route-${index}`)) {
        
        mapInstance.addSource(`additional-route-${index}`, {
          type: 'geojson',
          data: routeGeoJSON,
        });
        console.log('hhhhhhhhh')
        mapInstance.addLayer({
          id: `additional-route-layer-${index}`,
          type: 'line',
          source: `additional-route-${index}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': "#000000",
            'line-width': 5,
          },
        });
      }

    })
  } catch (error) {
    console.error('Error fetching routes:', error);
  }
};
const removePreviousLayersAndSources = async() => {
  if(!map) return
  if(!selectedNav||!oldSelectedNav) return
  setRemoveMarkersSubRoute(true)
  const mapInstance = map
    const routeLayerId = `route-layer-${0}`;
    const routeHitboxLayerId = `route-hitbox-layer-${0}`;
    const routeSourceId = `route-${0}`;
    const hitboxSourceId = `route-hitbox-${0}`;
    const countAdditional = await getRouteOneAdditionlCount(oldSelectedNav)        
  
    // Remove route layers and sources if they exist
    if (mapInstance.getLayer(routeLayerId)) {
      mapInstance.removeLayer(routeLayerId);
    }
    if (mapInstance.getSource(routeSourceId)) {
      mapInstance.removeSource(routeSourceId);
    }
    if (mapInstance.getLayer(routeHitboxLayerId)) {
      mapInstance.removeLayer(routeHitboxLayerId);
    }
    if (mapInstance.getSource(hitboxSourceId)) {
      mapInstance.removeSource(hitboxSourceId);
    }

    // Remove additional route layers and sources if they exist
    console.log('countAdditional==>',countAdditional)
    for(let i=0 ; i<=parseInt(countAdditional.response); i++){
      console.log('i==>',i)
    const additionalLayerId = `additional-route-layer-${i}`;
    const additionalSourceId = `additional-route-${i}`;
    if (mapInstance.getLayer(additionalLayerId)) {
      mapInstance.removeLayer(additionalLayerId);
    }
    if (mapInstance.getSource(additionalSourceId)) {
      mapInstance.removeSource(additionalSourceId);
    }}
   
  }

  const addAdditional = async()=>{
    if(!map) return

    const mapInstance = map
    let additional =  await getRouteOneAdditionlForPoc(selectedNav)
    console.log('additional===>',additional)
    additional.forEach((route,index)=>{
      const routeGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route.geoJSON.coordinates, // Assuming geoJSON is directly available
        },
      };
      if (!mapInstance.getSource(`additional-route-${index}`)) {
        
        mapInstance.addSource(`additional-route-${index}`, {
          type: 'geojson',
          data: routeGeoJSON,
        });
        console.log('hhhhhhhhh')
        mapInstance.addLayer({
          id: `additional-route-layer-${index}`,
          type: 'line',
          source: `additional-route-${index}`,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': "#000000",
            'line-width': 5,
          },
        });
      }
  
    })
    setMapMarkers((prevState)=>{
      prevState.forEach((m) => m.marker.remove());
      return [];
    })
  
  }
 
  const romveAdd = async()=>{
    if(!map) return

    const mapInstance = map
    const countAdditional = await getRouteOneAdditionlCount(oldSelectedNav)        

    // Remove additional route layers and sources if they exist
    console.log('countAdditional==>',countAdditional)
    for(let i=0 ; i<=parseInt(countAdditional.response); i++){
      console.log('i==>',i)
    const additionalLayerId = `additional-route-layer-${i}`;
    const additionalSourceId = `additional-route-${i}`;
    if (mapInstance.getLayer(additionalLayerId)) {
      mapInstance.removeLayer(additionalLayerId);
    }
    if (mapInstance.getSource(additionalSourceId)) {
      mapInstance.removeSource(additionalSourceId);
    }}
  }
  useEffect(() => {
    // Call function to remove previous layers before adding new ones
    const execute = async()=>{
      if(selectedNav!=null){         
        setRouteSelection(false)
      }
      await removePreviousLayersAndSources();
      await fetchRoutes()
    }
    execute()
  }, [selectedNav]);

  useEffect(() => {
    // Call function to remove previous layers before adding new ones
    const execute = async()=>{
      await romveAdd();
      await addAdditional()
        }
    if(submitAdditional){
      execute()
      setSubmitAdditional(false)
    };
  }, [submitAdditional]);


  useEffect(()=>{
    if(!map) return
    if (mapMarkers.length == 2) {
      fetchRouteBetweenMarkers(mapMarkers[0], mapMarkers[1]);
    }
    else{

      if (map.getLayer('route-plot')) {
        map.removeLayer('route-plot');  
        if (map.getSource('route')) {
          map.removeSource('route');
        }      
      }

    }
  },[mapMarkers])


  const [alternativeRoutes,setAlternativeRoutes]  = useState([])
  const getDirections = async (updatedMarkers) => {
    try {
       // Extract coordinates for waypoints
       const points = updatedMarkers.map((m) => [m.lng, m.lat]);
      console.log('points===>',points)
       // Join coordinates into a string to pass to the Directions API
      //  const waypoints = points.map(point => point.join(',')).join(';');
   
      let start = `${points[0]}`
      let end = `${points[points.length-1]}`

              const lineString = turf.lineString(points);
              let obj = {start,end,geoJSON:{coordinates: points}, distance:turf.length(lineString,{units: 'meters'}).toFixed(3)}
              setSelectedRoute(obj);  
      console.log('updatedMarkers===>', updatedMarkers);
                if (map.getLayer('selected-route')) {
                  map.removeLayer('selected-route');
                  map.removeSource('selected-route');
                }   
                map.addSource(`selected-route`, {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: points
                  }               
                 },
              });
  
              // Add route layer for each alternate route with profile-specific color
              map.addLayer({
                id: `selected-route`,
                type: 'line',
                source: `selected-route`,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#00F', // Different color for each profile and route
                  'line-width': 6,
                  'line-opacity': 0.7,
                },
              });
    
      // });
      // Define profiles (driving, walking, cycling)
      // setAlternativeRoutes([])
      // const profiles = ['driving', 'walking', 'cycling'];
      // Loop through each profile and get the directions
    //   for (let profile of profiles) {
    //     // Make the API call to get directions for the specified profile
    //     const response = await axios.get(
    //       `https://api.mapbox.com/directions/v5/mapbox/${profile}/${waypoints}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
    //     );
    //     // Handle the response if routes are available
    //     let alt = []

    //     setAlternativeRoutes((prevSate)=>{      
    //     // let preSate = await Promise.resolve(prevSate)
    //     // preSate.forEach(layerId => {
    //     //   if (map.getLayer(layerId)) {
    //     //     map.removeLayer(layerId);
    //     //     map.removeSource(layerId);
    //     //   }
    //     if (response.data.routes.length > 0) {
    //       // Clear existing routes if any
    //       if (map) {
    //         // Loop through each route and add it to the map
    //         response.data.routes.forEach((route, index) => {
    //           const routeGeoJson = route.geometry;
  
    //           // Add route source
    //           // map.addSource(`${profile}-route-${index}`, {
    //           //   type: 'geojson',
    //           //   data: {
    //           //     type: 'Feature',
    //           //     geometry: routeGeoJson,
    //           //   },
    //           // });
  
    //           // // Add route layer for each alternate route with profile-specific color
    //           // map.addLayer({
    //           //   id: `${profile}-route-${index}`,
    //           //   type: 'line',
    //           //   source: `${profile}-route-${index}`,
    //           //   layout: {
    //           //     'line-join': 'round',
    //           //     'line-cap': 'round',
    //           //   },
    //           //   paint: {
    //           //     'line-color': getRouteColor(profile, index), // Different color for each profile and route
    //           //     'line-width': 6,
    //           //     'line-opacity': 0.7,
    //           //   },
    //           // });
    //           console.log('prevSate====>',prevSate)
    //           if (prevSate&&!prevSate.some(item => JSON.stringify(item) === JSON.stringify(routeGeoJson))) {
    //             alt.push(routeGeoJson);
    //           }
    //         });
    //         return [...prevSate,...alt]
    //       }
    //       return prevSate
    //     }
    //     return prevSate 
    //   })
    // }
    } catch (error) {
      console.error('Error fetching directions:', error);
    }
  };
  
  // Function to get color based on profile and route index
  const getRouteColor = (profile, index) => {
    const colors = {
      driving: ['#3887be', '#1e90ff', '#87cefa'],   // Blue tones for driving
      walking: ['#00b300', '#66ff33', '#b3ff99'],   // Green tones for walking
      cycling: ['#ff7f00', '#ff4500', '#ff6347'],   // Orange tones for cycling
    };
  
    // Choose color based on the profile and index of the route
    return colors[profile][index] || '#000000';  // Default to black if something goes wrong
  };
  

  const fetchRouteBetweenMarkers = async (start, end) => {
    // const directions = new MapboxDirections({
    //   accessToken: mapboxgl.accessToken,
    //   unit: 'metric',
    //   profile: 'mapbox/driving',
    //   controls: {
    //     inputs: false, // This hides the input controls (start/end fields)
    //     instructions: false, // This hides the route instructions
    //   }
    // });

    // directions.setOrigin([start.lng, start.lat]);
    // directions.setDestination([end.lng, end.lat]);
  
    // directions.on('route', (e) => {
    //   if (e.route && e.route.length > 0) {
    //     const route = e.route[0];
    let updatedMarkers = [start, end]
    const points = updatedMarkers.map((m) => [m.lng, m.lat]);

        const lineString = turf.lineString(points);
        console.log('e.route==>',lineString)

        setSelectedRoute(lineString);
        const routeGeoJSON = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: points, // Decode the selected route geometry
          },
        };

        if (!map.getSource('route')) {
          console.log("-das=da=sd=asd=asd=as=d")
          map.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON,
          });

          map.addLayer({
            id: 'route-plot',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#008000',
              'line-width': 8,
            },
          });
        }
      }

        

      
  //   });
  // };

  const loadonMap = async (routeGeoJson) =>{
              let start = `${markers[0].lng},${markers[0].lat}`
              let end = `${markers[markers.length-1].lng},${markers[markers.length-1].lat}`

              let obj = {start,end,geoJSON:routeGeoJson, distance:turf.length(routeGeoJson,{units: 'meters'}).toFixed(3)}
              setSelectedRoute(obj);
    console.log(markers)
              if (map.getLayer('selected-route')) {
                map.removeLayer('selected-route');
                map.removeSource('selected-route');
              }            

              // Add route source
              map.addSource(`selected-route`, {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  geometry: routeGeoJson,
                },
              });
  
              // // Add route layer for each alternate route with profile-specific color
              map.addLayer({
                id: `selected-route`,
                type: 'line',
                source: `selected-route`,
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#3887be', // Different color for each profile and route
                  'line-width': 6,
                  'line-opacity': 0.7,
                },
              });
  }
  // const plotRouteBetweenPoints = async (start, end) => {
  //   const directions = new MapboxDirections({
  //     accessToken: mapboxgl.accessToken,
  //     unit: 'metric',
  //     profile: 'mapbox/driving',
  //     controls: {
  //       inputs: false, // This hides the input controls (start/end fields)
  //       instructions: false, // This hides the route instructions
  //     }
  //   });

  //   directions.setOrigin([start.lng, start.lat]);
  //   directions.setDestination([end.lng, end.lat]);
  
  //   directions.on('route', (e) => {
  //     if (e.route && e.route.length > 0) {
  //       const route = e.route[0];
  //       console.log('e.route==>',e.route)
  //       setSelectedRoute(route);
  //       const routeGeoJSON = {
  //         type: 'Feature',
  //         geometry: {
  //           type: 'LineString',
  //           coordinates: decodeGeometry(route?.geometry), // Decode the selected route geometry
  //         },
  //       };

  //       if (!map.getSource('route')) {
  //         console.log("-das=da=sd=asd=asd=as=d")
  //         map.addSource('route', {
  //           type: 'geojson',
  //           data: routeGeoJSON,
  //         });

  //         map.addLayer({
  //           id: 'route-plot',
  //           type: 'line',
  //           source: 'route',
  //           layout: {
  //             'line-join': 'round',
  //             'line-cap': 'round',
  //           },
  //           paint: {
  //             'line-color': '#008000',
  //             'line-width': 8,
  //           },
  //         });
  //       }
  //     }

        

      
  //   });
  // };

  const handleRemoveItem = (index) => {
    const newItems = markers.filter((item, i) =>{ 
      if(i !== index){
        return item
      }
       item.marker.remove()
      }); // Filter out the item at the given index

      newItems.forEach((marker, index) => {
        if (index == 0 ) {
          let elements = marker.marker.getElement()
          let svg = elements.querySelector('svg')
          let path = svg.querySelector('path')
          path.setAttribute('fill', 'red');
        } 
        else if (index == newItems.length - 1) {
          // Make first and last markers red
          let elements = marker.marker.getElement()
          let svg = elements.querySelector('svg')
          let path = svg.querySelector('path')
          path.setAttribute('fill', 'green');


        } else {
          // Make other markers blue
          let elements = marker.marker.getElement()
          let svg = elements.querySelector('svg')
          let path = svg.querySelector('path')
          path.setAttribute('fill', 'blue');
        }
      });
      
    setMarkers(newItems)
    getDirections(newItems);

  };
  const [strArray, setStrArray] = useState(['','']) 
  useEffect(()=>{
    if(markers.length>0){
      let stringArray = []
      markers.forEach(i=>{
        stringArray.push(`${i.lng},${i.lat}`)
      })
      setStrArray(stringArray)
    }
  },[markers])
  const [suggestions, setSuggestions] = useState([]); // Store location suggestions

  const handleSearch = async (location,index) => {
    setStrArray((prev)=>{
      prev[index]=location
      if(!location){
        prev[index]= ''
      } 
      setSuggestions([])          
      return prev    
    })
    if (!location) return;
    const encodedLocation = encodeURIComponent(location); // Encode the location name to make it URL-safe
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedLocation}.json?access_token=${mapboxgl.accessToken}`;

    try {
      const response = await axios.get(url);

      // If the response has results, update the coordinates
      if (response.data.features.length > 0) {
        const { center, place_name } = response.data.features[0]; // center holds the [longitude, latitude] array      
        setSuggestions(response.data.features);
        
        // const [lng, lat] = center; // Extract longitude and latitude
        // console.log({ lat, lng }); // Assign latitude and longitude
        
        
        // Center map on the new location and add marker
        // if (map.current) {
        //   map.current.flyTo({
        //     center: [lng, lat], // Fly to the new location
        //     zoom: 12, // Zoom in to the new location
        //   });

          // Add a marker at the location
        }
      // } else {
      //   // console.log({ lat, lng }); // Assign latitude and longitude
      //   console.log('Location not found');
      // }
    } catch (err) {
      // console.log({ lat, lng }); // Assign latitude and longitude
      console.log('Error fetching location');
    }
  };
console.log('strArray==>',strArray)
  return (
    <div>
      <SideNavPOC  routes={routes} setSelectedNav={setSelectedNav}/>
      {routeSelection&&
      
      <div
    style={{
      position: 'absolute',
      top: '65px',
      left: '15px',
      zIndex: 10,  // Ensure the buttons are on top of the map
    }}
  >
    {alternativeRoutes?.map((i,index)=>
    <button
      id="add-route-btn"
      className="map-button"
      style={{
        background: '#BD1D23',
        border: '0',
        borderRadius: '5px', // Smaller padding
        cursor: 'pointer',    // Show pointer cursor on hover
      }}
      onClick={()=>loadonMap(i)}
    >
      {index+1}
    </button>
    )}
         <div
        style={{
          position: 'absolute',
          zIndex: 10,
          background: 'darkgrey',
          padding: '10px'
        }}
      >
        <label>Markers:</label>
        {
        markers.length<3?(
          <>
            <div style={{ display: 'flex', alignItems: 'center', margin:'1%' }}>
            <input
              type="text"
              value={strArray[0]}
              onChange={(e) => handleSearch(e.target.value,0)}  // Update state as the user types
              style={{
                padding: '5px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                width: '250px',
              }}
            />
            <button
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
                color: '#BD1D23',
              }}
            >
            </button>
            </div>
            {suggestions.length > 0 && (
        <ul style={{ marginTop: '10px' }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              style={{
                padding: '5px',
                cursor: 'pointer',
                background: '#f0f0f0',
                marginBottom: '5px',
              }}
              // onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}

            <div style={{ display: 'flex', alignItems: 'center', margin:'1%' }}>
          <input
            type="text"
            value={markers.length>1?`${markers[1].lng},${markers[1].lat}`:''}
            readOnly
            style={{
              padding: '5px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '250px',
            }}
          />
           <button
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontSize: '16px',
              color: '#BD1D23',
            }}
          >
          </button>
          </div>
          </>
        )
      :  
       ( markers.map((i,index)=>
       <div style={{ display: 'flex', alignItems: 'center', margin:'1%' }}>
        <input
          type="text"
          value={strArray[1]}
          style={{
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            width: '250px',
          }}
        />
         <button
          onClick={() => handleRemoveItem(index)} 
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            color: '#BD1D23',
          }}
        >
          <strong>X</strong> {/* "X" Icon */}
        </button>
        </div>))}
      </div>

  </div>
  }

      <div id="map" style={{ width: '100%', height: 'calc(100vh - 50px)' }}></div>  
    </div>
  );
};

export default MapWithDirections;
