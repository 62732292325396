import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import TenantManagementTable from './tenant_management_table.component';
import './style.css';
import { TenantHeader } from './tenant_header.component';
import { getAllTenants } from './tenantFunctions.js/get_all_tenants.function';
import { useBackdrop } from '../../common/components/backDrop_context.component';
import { logout } from '../../common/functions/log_out.function';
import { notify } from '../../common/functions/react-toastify.function';
import {useNavigate} from 'react-router-dom';

export const TenantMainPage = () => {
  const [tenant, setTenant] = useState([])
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const navigate = useNavigate()
  const tenantId = sessionStorage.getItem('tenantId')
  const handleLogout = async () => {
    try {
      const result = await logout();
      console.log(result)
      navigate('/')
      notify('success', result.message)
    } catch (error) {
      notify('error', error.response.data.message)
      navigate('/')
    }
  };
  useEffect(() => {
    if(tenantId !== 'null'){
      handleLogout()
    }
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    showBackdrop();
    const allTenantsData = await getAllTenants();
    hideBackdrop();
    setTenant(allTenantsData?.response);
  };
  return (
    <>
      <Box
        margin={3}
        sx={{
          border: '2px solid transparent',  // Outer border
          borderRadius: '15px',  // Smooth rounded corners
          background: 'linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, #BD1D23, #FF5733) border-box',  // Border with gradient
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Slight shadow for a floating effect
          padding: '20px',  // Padding inside the border
        }}
      >
        <TenantHeader handleLogout={handleLogout}/>
        <TenantManagementTable tenants={tenant} fetchTenants={fetchTenants} />
      </Box>
    </>

  );
};
