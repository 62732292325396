import { Box, CircularProgress, TextField, Button, Select, MenuItem, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { width } from '@mui/system';
import { useParams } from 'react-router-dom';  // Importing useParams to access URL params
import { getRouteDetailTable } from '../functions/get_routes_for_poc.function';  // Assuming this function is used to fetch routes

export default function RouteAnalysisDetailTable() {
    
    const { startLocation, endLocation } = useParams();  // Extract startLocation and endLocation from URL params
    const [loading, setLoading] = useState(true);  // State to track if data is loading
    const [routeData, setRouteData] = useState([]);  // State to store the fetched route data
    const [error, setError] = useState(null);  // State to handle errors
    const [colNums, setColNums] = useState([]);  // State to store the fetched route data
    
    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                const data = await getRouteDetailTable(startLocation, endLocation);  // Fetch the data from the API
                let rowNumber = []
                for(let i = 1 ; i<=data.rowLargest; i++){
                    rowNumber.push(`ROW ${i}`)
                }
                setColNums(rowNumber)
                setRouteData(data.data);  // Store the fetched data
                setLoading(false);  // Set loading to false after data is fetched
                } catch (error) {
                setError('Error fetching route details: ' + error.message);  // Handle any errors
                setLoading(false);  // Stop loading in case of an error            
            }
        };

        // Fetch routes only if startLocation and endLocation are available
        if (startLocation && endLocation) {
            fetchRoutes();
        }

    }, [startLocation, endLocation]);  // Effect runs when either startLocation or endLocation changes

    return (
        <>
            {   loading?(
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Box>):
            (
            <Box
                sx={{
                    width: '100%',  // Form width
                    backgroundColor: 'white',  // Form background
                    padding: 3,
                    borderRadius: 2,  // Rounded corners
                    boxShadow: 2,     // Box shadow for styling
                    margin: '0 auto'  // Center the form on the page
                }}
            >                
                    <Box sx={{ overflowX: 'auto' }} style={{height:"80vh"}}>
                    <TableContainer component={Paper} style={{width:"100%"}}>
                      <Table aria-label="route comparison table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell colSpan={3} align="center">Origin</TableCell>
                            <TableCell  align="center"></TableCell>
                            <TableCell colSpan={5} align="center">Destination</TableCell>
                          </TableRow>
                          <TableRow>
                            
                          <TableCell />
                            <TableCell colSpan={3} align="center">{startLocation}</TableCell>
                            <TableCell  align="center"></TableCell>
                            <TableCell colSpan={5} align="center">{endLocation}</TableCell>                       
                          </TableRow>
                          <TableRow>                              
                          {routeData.map(i=>{
                            return(
                                <TableCell colSpan={5} align="center" sx={{ borderRight: '2px solid #ddd' }}>{i.routeName}</TableCell>                                
                              )
                            }
                            
                          )}
                          </TableRow>                              
                          <TableRow>
                            <TableCell >Length</TableCell>
                            {routeData.map((i,index)=>{
                                if(index == 0){
                                    return(<>
                                        <TableCell ></TableCell>
                                        <TableCell  align="center" >{i.length}</TableCell>                                
                                        <TableCell ></TableCell>
                                        <TableCell sx={{ borderRight: '2px solid #ddd' }}></TableCell>

                                    </>
                                    )
                                }
                                else{                                    
                                    return(
                                        <TableCell colSpan={4} align="center" sx={{ borderRight: '2px solid #ddd' }}>{i.length}</TableCell>                                
                                    )
                                }
                            }
                            
                          )}
                          </TableRow>
                          <TableRow >
                              <TableCell sx={{ width:"13%" }}></TableCell>
                              {routeData.map(i=>{
                            return(
                                <>
                                <TableCell sx={{ width:"13%" }}>length</TableCell>
                                <TableCell sx={{ width:"13%" }}>per unit cost</TableCell>
                                <TableCell sx={{ width:"13%" }}>amount</TableCell>
                                <TableCell sx={{ borderRight: '2px solid #ddd', width:"13%"}}>availability</TableCell>
                                </>
                                )
                            }
                            
                          )}
                             
                            </TableRow>
                        </TableHead>
                        <TableBody>
                          {['Normal', 'Rocky', 'Bridge Attachment', 'Asphalt'].map((terrain, index) => (
                              <TableRow key={index}>
                              <TableCell sx={{ width:"13%" }}>{terrain}</TableCell>
                              {routeData.map((i)=>
                                <>
                                <TableCell sx={{ width:"13%" }}>{i[terrain].length}</TableCell>
                                <TableCell sx={{ width:"13%" }}>{i[terrain].cost!==0?i[terrain].cost/i[terrain]['array'].length:0}</TableCell>
                                <TableCell sx={{ width:"13%" }}>{i[terrain].amount}</TableCell>
                                <TableCell sx={{ borderRight: '2px solid #ddd', width:"13%"}}></TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                          {colNums.map((i,index)=>{
                            return(
                              <TableRow key={index}>
                              <TableCell sx={{ width:"13%" }}>{i}</TableCell>
                              {routeData.map((i)=>
                                {return (i.ROWs.length==0?<>
                                    <TableCell sx={{ width:"13%" }}></TableCell>
                                    <TableCell sx={{ width:"13%" }}></TableCell>
                                    <TableCell sx={{ width:"13%" }}></TableCell>
                                    <TableCell sx={{ borderRight: '2px solid #ddd', width:"13%"}}></TableCell>
                                    </>:i.ROWs.map(j=>{
                                    return(
                                    <>
                                    <TableCell sx={{ width:"13%" }}>{j.length}</TableCell>
                                    <TableCell sx={{ width:"13%" }}>{j.cost}</TableCell>
                                    <TableCell sx={{ width:"13%" }}>{j.amount}</TableCell>
                                    <TableCell sx={{ borderRight: '2px solid #ddd', width:"13%"}}>{j.availbility}</TableCell>
                                    </>)}))}
                              )}
                            </TableRow>
                          )})}
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Total Cost</TableCell>
                          {routeData.map((i,index)=>
                            <TableCell colSpan={index==0?4:5} sx={{borderRight: '2px solid #ddd'}} >{i.totalCost}</TableCell>
                            
                        )}
                        </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>              
            </Box>
            
            )
            
            }
        </>
    );
}
